import React, { useState, useEffect } from "react";

import './ShowFile.css';

import { SvgClose, SvgSetaRight } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

export default function PopUp_ShowFile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ type, setType ]       = useState('single');
    const [ file, setFile ]       = useState([]);
    const [ indexFile, setIndex ] = useState(0);

    useEffect(()=>{
        RegisterModalData('ShowFile', setModaldata);
        RegisterModalObserver('ShowFile', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setType(modalData.type);
            setFile(modalData.file);
            setIndex(modalData.indexFile);
        }
    }, [showPopUp]);

    function ClosePopUp(){
        setType('');
        setIndex(0);
        setFile([]);
        SetModalState('ShowFile', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all ShowFile">
                        {
                            type == "single" ? null :
                            <div className="div_arrow">
                                {
                                    indexFile - 1 >= 0 ?
                                    <div className="arrow" onClick={ ()=>{ setIndex(indexFile - 1) } }>
                                        <SvgSetaRight className="icons icons_left" color="#ffffff" />
                                    </div> : 
                                    <div className="arrow">
                                        <SvgSetaRight className="icons icons_left" color="#535353" />
                                    </div>
                                }
                                <div className="ShowFile" />
                                {
                                    indexFile < file.length - 1  ?
                                    <div className="arrow" onClick={ ()=>{ setIndex(indexFile + 1) } }>
                                        <SvgSetaRight className="icons" color="#ffffff" />
                                    </div> : 
                                    <div className="arrow">
                                        <SvgSetaRight className="icons" color="#535353" />
                                    </div>
                                }
                            </div>
                        }

                        <div className="div_data">
                            <div className="title title_file">
                                <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>
                                    <SvgClose color="#F00000" className="icons" />
                                </div>
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                {
                                    type == "single" ? 
                                    <img alt="img" src={ file } className="show_img" />
                                    :
                                    file.length > 0 ? 
                                    <img alt="img" src={ file[indexFile].file } className="show_img" />  
                                    : null   
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}