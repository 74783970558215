import { useState, useEffect, useRef } from "react";

import './Details.css';

import { GetPurchase, RegisterPurchase, SetPurchaseSingle } from "interface/Purchase";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { Link, useNavigate } from "react-router-dom";

import { SetModalData, SetModalState } from "interface/PopUp";

import PopUp_ShowFile from "components/PopUp/ShowFile";
import { SvgArrow_3 } from "components/SvgFile";

export default function Page_Product_Details(props){

    const navigate = useNavigate();
    const [ url, setUrl ] = useState(window.location.href.split("/"));

    const [ qtd, setQtd ] = useState(1);
    const [ flavor, setFlavor ] = useState('');
    const [ idPage, setIdPage ] = useState(GetListPag('currentPage_id')); 
    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));

    const [ showData, setShowData ] = useState(InitialData('all'));

    const [ listProduct, setListProduct ] = useState([
        {
            "type": 'add', 
            "id": showData.id, 
            "qtd": 1, 
            "flavor": '', 
            "showData": showData
        }
    ])

    function InitialData(type){
        if(GetDataPage('products').find(item => item.url_page == url[3])){
            const newData = GetDataPage('products').find(item => item.url_page == url[3]);
            return newData;
            
        }else {
            navigate("/");
            SetListPag('page', 'index');
            window.history.pushState("", "", "/");
        }
    }

    function HandleData(type, id, index, value){
        const newData = [...listProduct];        
        if(type == 'qtd'){
            newData[index]['qtd'] = '';
            newData[index]['qtd'] = value;
        }else {
            newData[index][type] = value;
        }        
        setListProduct(newData);
    }

    function AddNewProduct(){
        const newData = [...listProduct];
        newData.push({ "type": 'add', "id": showData.id, "qtd": 1,  "flavor": '', "showData": showData });
        setListProduct(newData);
    }

    function RemuveProduct(index){
        const newData = [...listProduct];
        newData.splice(index, 1);
        setListProduct(newData);
    }

    function AddProductPurchase(elem, index){
        let qtdProduct = 0;
        if(elem.showData.flavor.length > 0){
            if(elem.showData.flavor.find(item => item.name_flavor == elem.flavor)){
                let infFlavor = elem.showData.flavor.find(item => item.name_flavor == elem.flavor);                
                qtdProduct = infFlavor.qtd;
            }
        }else {
            qtdProduct = elem.showData.qtd;
        }

        let qtdProductPurchase = 0;
        if(purchase.length > 0){
            if(purchase.find(item => item.id == elem.id)){
                let dataProduct = purchase.find(item => item.id == elem.id);
                
                if(elem.showData.flavor.length > 0){
                    if(dataProduct.flavor == elem.flavor){
                        qtdProductPurchase = dataProduct.qtd;
                    }
                }else {
                    qtdProductPurchase = dataProduct.qtd;
                }
            }
        }
        
        return(
            <span className="div_btn" key={ index }>
                {
                    elem.showData.flavor.length > 0 ?
                    <div className="add_flavor">
                        <select className="select_flavor" onChange={ (e)=>{ HandleData('flavor', elem.id, index, e.target.value) } } value={ elem.flavor }>
                            <option value="">Selecione um sabor</option>
                            {
                                elem.showData.flavor.map((elem_1, index_1)=>{
                                    if(elem_1.qtd !=0){
                                        return(
                                            <option value={ elem_1.name_flavor } key={ index_1 }>{ elem_1.name_flavor }</option>
                                        )
                                    }
                                })
                            }
                        </select>
                    </div>
                    : null
                }
                <div className="add_number">
                    {
                        elem.qtd == 1 ?
                        <div className="add_or_remuve">-</div> : 
                        <div className="add_or_remuve" onClick={ ()=>{ HandleData('qtd', elem.id, index, elem.qtd - 1); } }>-</div>
                    }
                    <div className="qtd">{ elem.qtd }</div>
                    {
                        elem.qtd >= qtdProduct ? 
                        <div className="add_or_remuve not_add">+</div>
                        :
                        <div className="add_or_remuve" onClick={ ()=>{ HandleData('qtd', elem.id, index, elem.qtd + 1); } }>+</div>
                    }
                </div>
                {
                    qtdProductPurchase == qtdProduct ? null 
                    // <div className="div_cart div_flavor">
                    //     <div className="btn_product">
                    //         Estoque máximo atingido
                    //     </div>
                    // </div>
                    :                    
                        elem.showData.flavor.length > 0 ?
                            elem.flavor !='' ? 
                            <div className="div_cart" onClick={ ()=>{ SetPurchaseSingle('add', elem.id, elem.qtd, elem.flavor, elem.showData) } }>
                                <div className="btn_product">
                                    Adicionar ao carrinho
                                </div>
                            </div> : 
                            <div className="div_cart div_flavor">
                                <div className="btn_product">
                                    Escolha um sabor
                                </div>
                            </div>
                        : 
                        <div className="div_cart" onClick={ ()=>{ SetPurchaseSingle('add', elem.id, elem.qtd, elem.flavor, elem.showData) } }>
                            <div className="btn_product">
                                Adicionar ao carrinho
                            </div>
                        </div>
                }
            </span>
        )
    }

    function OpenFile(type, file, index){
        SetModalData('ShowFile', { "type": type, "file": file, "indexFile": index });
        SetModalState('ShowFile', true);
    }

    useEffect(()=>{
        RegisterListPag('currentPage_id', setIdPage);
        RegisterPurchase('purchase', setPurchase);
    }, []);

    useEffect(()=>{
        setPurchase(GetPurchase('purchase'));
    }, [purchase]);

    return(
        <div className="Page_Product_Details">
            
            <div className="container">
                <Link to="/product" className="div_return" onClick={ ()=>{ SetListPag('page', 'product'); props.ReturnIndex(); } }>
                    <SvgArrow_3 className="icons" color="#6b7d5c" />
                    <div className="">
                        Voltar
                    </div>
                </Link>
            </div>

            <div className="container">
                <div className="div_img">
                    <div className="img" onClick={ ()=>{ OpenFile('single', showData.file, 0) } }>
                        <img alt="img" src={ showData.file } className="img_product" />
                    </div>
                    <div className="list_img">
                        {
                            showData.list_img.map((elem, index)=>{
                                return(
                                    <div className="show_img" style={ { backgroundImage: 'url("' + elem.file + '")' } }  onClick={ ()=>{ OpenFile('list', showData.list_img, index) } } key={ index } />
                                )
                            })
                        }
                    </div>
                </div>
                <div className="div_details_title div_details_title_mobile">{ showData.name }</div>
                <div className="div_details">

                    <div className="div_details_title div_details_title_deskotop">{ showData.name }</div>

                    {
                        showData.qtd == 0 && showData.qtd_total == 0 ? 
                        <div className="div_btn">
                            <div className="div_cart div_cart_active unavailable">
                                <div className="btn_product">Indisponível</div>
                            </div>
                        </div>
                        :
                        <div className="div_btn show_product_btn">
                            {
                                listProduct.map((elem, index)=>{
                                    return AddProductPurchase(elem, index);
                                })
                            }
                        </div>
                    }

                    <div className="text">
                        Valor unitário 
                        {
                            showData.cash_promotional == "" ? " € " + showData.cash :
                            <>
                                <span className="promotional"> € { showData.cash }</span> por € { showData.cash_promotional }
                            </>
                        }
                    </div>
                    <div className="text" dangerouslySetInnerHTML={ { __html: showData.text.replaceAll('&#34;', '"') } } />                    
                </div>
            </div>
            <PopUp_ShowFile />
        </div>
    )
}