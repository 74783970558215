
let DataPage = {
    "purchase" : []
};

let NotifyDataPage = {
    "purchase" : []
}

export function SetPurchase(value) {
    DataPage = value;
    NotifyDataPage["purchase"].forEach(element => { element(value['purchase']); });
}

export function SetPurchaseSingle(type, value, qtdPur, flavor, data) {  
    if(type == 'remuve'){ 
        let index = DataPage['purchase'].findIndex(item => item.id == value);
        DataPage['purchase'].splice(index, 1);

    }else if(type == 'edit'){
        let index = DataPage['purchase'].findIndex(item => item.id == value);
        DataPage['purchase'][index][flavor] = qtdPur;

    }else if(type == 'addList'){
        value.map((elem, index)=>{
            DataPage['purchase'].push({ "id": elem.id, "qtd": elem.qtd, "flavor": elem.flavor, "data": elem.showData });
        })
    }else {
        if(DataPage['purchase'].find(item => item.id == value)){
            let purchaseData = DataPage['purchase'].filter(item => item.id == value);
            if(purchaseData.find(item => item.flavor == flavor)){
                let index = purchaseData.findIndex(item => item.flavor == flavor);
                DataPage['purchase'][index]['qtd'] = DataPage['purchase'][index]['qtd'] + qtdPur;
            }else {
                DataPage['purchase'].push({ "id": value, "qtd": qtdPur, "flavor": flavor, "data": data });
            }
        }else {
            DataPage['purchase'].push({ "id": value, "qtd": qtdPur, "flavor": flavor, "data": data });
        }
    }

    if(type == 'remuve_all'){ 
        DataPage['purchase'] = [];
        NotifyDataPage['purchase'].forEach(element => {
            element([]);        
        });
    }else {
        NotifyDataPage['purchase'].forEach(element => {
            element([{ "id": value, "qtd": qtdPur, "flavor": flavor, "data": data }]);        
        });
    }
}

export function GetPurchase(key){
    return DataPage[key];
}

export function RegisterPurchase(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
