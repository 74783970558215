import { useState, useEffect } from "react";

import './Logado.css';

import Cookies from 'universal-cookie';

import { GetDataPage } from "interface/Data";
import { SetListPag } from "interface/Page";
import { GetUserData, SetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";

import { cookie_site_login, cookie_site_password, cookiesRegister } from "fixedData";

import { Register } from "services/login";

import PopUP_ShowPurchase from "components/PopUp/ShowPurchase";

export default function Page_Logado(props){

    const cookies  = new Cookies();
    
    const [ topic, setTopic ]             = useState('products');
    const [ dataUser, setDataUser ]       = useState(GetUserData('user'));
    const [ listProduct, setListProduct ] = useState(GetDataPage('products'));

    const [ purchase, setPurchase ]         = useState(dataUser.purchase);
    const [ name, setName ]                 = useState(dataUser.name);
    const [ email, setEmail ]               = useState(dataUser.email);
    const [ contact, setContact ]           = useState(dataUser.contact);
    const [ household, setHousehold ]       = useState(dataUser.household);
    const [ postalCode, setPostalCode ]     = useState(dataUser.postal_code);
    const [ location, setLocation ]         = useState(dataUser.location);
    const [ district, setDistrict ]         = useState(dataUser.district);
    const [ password, setPassword ]         = useState('');

    function Logout(){        
        cookies.remove(cookie_site_password, '', '/', cookiesRegister);
        cookies.remove(cookie_site_login, '', '/', cookiesRegister);
      
        SetUserData('user', {});
        SetListPag('page', 'login');  

        props.CallbackSuccess(false);
    }

    function HandleData(index, status){
        const newData = [...purchase];
        newData[index]['status'] = status;
        setPurchase(newData);
    }
    
    function EditProfile(event){
        event.preventDefault();
        Register('edit_profile', dataUser.id, name, email, contact, household, postalCode, location, district, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        SetModalData('ReturnResponse', { "page": "missingData", "text": "Cadastro realizado com sucesso!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro", "text": "Erro ao registrar informações, tente novamente!" });
        SetModalState('ReturnResponse', true);
    }

    function DetailsProduct(statusProduct, date, cash_total, list){
        SetModalData('ShowPurchase', { "statusProduct": statusProduct, "date": date, "cash_total": cash_total, "list": list });
        SetModalState('ShowPurchase', true);
    }

    function CurrentPage(){
        switch (topic) {
            case "products":
                return(
                    <div className="div_products">
                        {
                            purchase.length > 0 ? 
                                purchase.map((elem, index)=>{
                                    let statusProduct = 'Pendente';
                                    if(elem.status == 1){
                                        statusProduct = 'Finalizada';
                                    }else if(elem.status == 2){
                                        statusProduct = 'Cancelada';
                                    }
                                    
                                    return(
                                        <div className="list_purchase" key={ index }>
                                            <div className="div_data">
                                                <div className="div_subtitle">Compra realizado na data { elem.date }</div>
                                                <div className="div_title">Valor total € { elem.cash_total }</div>
                                            </div>
                                            <div className="div_btn">
                                                <div className="btn" onClick={ ()=>{ DetailsProduct(statusProduct, elem.date, elem.cash_total, elem.list) } }>Visualizar produtos</div>
                                                <div className="status_purchase">
                                                    Status: { statusProduct }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                             : 
                            <div className="text">Nenhuma compra realizada até o momento!</div>
                        }
                    </div>
                )

            case "profile":
                return(
                    <form onSubmit={ EditProfile } className="div_login">
                        <div className="div_row">
                            <div className="div_input">
                                <div className="name_input">Nome*</div>
                                <div className="input">
                                    <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } maxLength="80" value={ name } required />
                                </div>
                            </div>
                            <div className="div_input">
                                <div className="name_input">E-mail*</div>
                                <div className="input">
                                    <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } maxLength="80" value={ email } required />
                                </div>
                            </div>
                            <div className="div_input">
                                <div className="name_input">Contato*</div>
                                <div className="input">
                                    <input type="text" className="" onChange={ (e)=>{ setContact(e.target.value) } } maxLength="80" value={ contact } required />
                                </div>
                            </div>
                        </div>
                        
                        <div className="div_row">
                            <div className="div_input">
                                <div className="name_input">Morada*</div>
                                <div className="input">
                                    <input type="text" className="" onChange={ (e)=>{ setHousehold(e.target.value) } } maxLength="120" value={ household } required />
                                </div>
                            </div>
                        </div>
                        
                        <div className="div_row">
                            <div className="div_input">
                                <div className="name_input">Código postal*</div>
                                <div className="input">
                                    <input type="text" className="" onChange={ (e)=>{ setPostalCode(e.target.value) } } maxLength="120" value={ postalCode } required />
                                </div>
                            </div>
                            <div className="div_input">
                                <div className="name_input">Localidade*</div>
                                <div className="input">
                                    <input type="text" className="" onChange={ (e)=>{ setLocation(e.target.value) } } maxLength="120" value={ location } required />
                                </div>
                            </div>
                            <div className="div_input">
                                <div className="name_input">Senha*</div>
                                <div className="input">
                                    <input type="password" className="" onChange={ (e)=>{ setPassword(e.target.value) } } />
                                </div>
                            </div>
                        </div>

                        <div className="div_button">
                            <button className="button_login">Salvar</button>
                        </div> 
                    </form>
                )
        }
    }

    useEffect(()=>{
        CurrentPage()
    }, [topic]);

    useEffect(()=>{
        setDataUser(GetUserData('user'));

        setPurchase(dataUser.purchase);
        setName(dataUser.name);
        setEmail(dataUser.email);
        setContact(dataUser.contact);
        setHousehold(dataUser.household);
        setPostalCode(dataUser.postal_code);
        setLocation(dataUser.location);
        setDistrict(dataUser.district);
        setPassword('');
    }, [dataUser]);
    
    return(
        <>
            <div className="Page_Logado">
                <div className="container">
                    <div className="div_topic">
                        <div className={ topic == "products" ? "name_active" : "name" } onClick={ ()=>{ setTopic('products') } }>Produtos comprados</div>
                        <div className={ topic == "profile" ? "name_active" : "name" } onClick={ ()=>{ setTopic('profile') } }>Editar perfil</div>
                        <div className="name" onClick={ ()=>{ Logout('') } }>Sair</div>
                    </div>
                    
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_ShowPurchase ReturnIndex={ props.ReturnIndex } />
        </>
    );
}