import { useState, useEffect } from "react";

import Cookies from 'universal-cookie';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { SvgArrow_3 } from "components/SvgFile";

import { cookie_site_login, cookie_site_password } from "fixedData";

import Menu from "components/Menu";
import Footer from "components/Footer";

import { GetDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import PopUP_Text from "components/PopUp/Text";
import PopUP_Health from "components/PopUp/Health";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";

import Page_Home from "components/Page/Home";
import Page_Login from "components/Page/Login";
import Page_About from "components/Page/About";
import Page_Logado from "components/Page/Logado";
import Page_Recover from "components/Page/Recover";
import Page_Product from "components/Page/Product";
import Page_Purchase from "components/Page/Purchase";
import Page_WhatWeDo from "components/Page/WhatWeDo";
import Page_Register from "components/Page/Register";
import Page_Product_Details from "components/Page/Product/Details";

import { Access } from "services/login";

import PopUP_Login from "components/PopUp/Login";
import PopUp_RecoverPassword from "components/PopUp/RecoverPassword";

export default function App() {

    const cookies                         = new Cookies();
    const [ loading, setLoading ]         = useState(false);
    const [ statusLogin, setStatusLogin ] = useState(false);

    const [ user, setUser ]     = useState(GetUserData('user'));
    const [ page, setPage ]     = useState(GetListPag('page'));
    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));

    const [ url, setUrl ]       = useState(window.location.href.split("/"));
    const [ infUrl, setInfUrl ] = useState(0);

    function CallbackSuccess(value){
        setStatusLogin(value);
    }

    function ReturnIndex(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    
    function NamePageUrl(){
        if(url){
            if(GetDataPage('products').find(item => item.url_page == url[3])){
                SetListPag('page', url[3]);
                        
            }else {
                let segund = url[3].split('=');
                if(GetDataPage('recover').find(item => item == segund[1])){
                    SetListPag('page', url[3]);

                }else {                    
                    switch (url[3]) {
                        case "index": case "about": case "whatWeDo": case "contact": case "product": case "product_details": case "login": case "purchase": case "register":
                                SetListPag('page', url[3]);
                            break;
                        default:
                                SetListPag('page', 'index');
                            break;
                    }
                }
            }
        }else{
            SetListPag('page', 'index');
        }
    }

    function CkickPage(value){      
        switch (value) {
            case "index": case "about": case "whatWeDo": case "contact": case "product": case "product_details": case "login": case "purchase":
                    SetListPag('page', value);   
                break;
        
            default: 
                    SetListPag('page', 'index');   
                break;
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag('idioma', setIdioma);

        RegisterUserData('user', setUser);
        if(cookies.get(cookie_site_login) || cookies.get(cookie_site_password)){
            let return_email = cookies.get(cookie_site_login);
            let return_pass  = cookies.get(cookie_site_password);
            Access('return', return_email, return_pass, ()=>{ CallbackSuccess(true) }, ()=>{ });
        }

        NamePageUrl();
        
        window.addEventListener("scroll", ()=>{
            if(window.scrollY >= 60){
                document.getElementById('return').classList.add('return_index');
                document.getElementById('return').classList.remove('return_close');
            }else {
                document.getElementById('return').classList.add('return_close');
                document.getElementById('return').classList.remove('return_index');
            }
        });
    }, []);

    useEffect(()=>{
        if(loading == true){
            document.body.style.overflow = "hidden";
        }else {
            document.body.style.overflow = null;
        }
    }, [loading]);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
    }, [idioma]);   

    return (
        <Router>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                 <div className="hourglassBackground">
                    <div className="hourglassContainer">
                        <div className="hourglassCurves" />
                        <div className="hourglassCapTop" />
                        <div className="hourglassGlassTop" />
                        <div className="hourglassSand" />
                        <div className="hourglassSandStream" />
                        <div className="hourglassCapBottom" />
                        <div className="hourglassGlass" />
                    </div>
                </div>
            </div>
            
            <div className="whatsapp">
                <a href="https://api.whatsapp.com/send?phone=351965056763" target="_blank">
                    <img alt="Whatsapp" src="./assets/whatsapp.svg" className="icon_whatsapp" />
                </a>
            </div>

            <div className="return_close" id="return" onClick={ ()=>{ ReturnIndex() } }>
                <SvgArrow_3 className="icon_whatsapp" color="rgb(255 181 0)" />
            </div>

            <Menu CkickPage={ CkickPage } setLoading={ setLoading } ReturnIndex={ ReturnIndex } />
            
            <Routes>
                <Route exact path="/" element={ <Page_Home ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                <Route exact path="*" element={ <Page_Home ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                
                <Route exact path="/about" element={ <Page_About ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                
                <Route exact path="/whatWeDo" element={ <Page_WhatWeDo ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                
                <Route exact path="/product" element={ <Page_Product ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />

                <Route exact path="/preview_product" element={ <Page_Product ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } />
                
                <Route exact path="/purchase" element={ <Page_Purchase ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } /> } />
                
                <Route exact path="/login" element={ statusLogin == true ? <Page_Logado ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } /> : <Page_Login ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } typePage="page_login" /> } />
                
                <Route exact path="/register" element={ <Page_Register ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } /> } />

                {
                    GetDataPage('products').map((elem, index)=>{                        
                        return(
                            <Route exact path={ "/" + elem.url_page } element={ <Page_Product_Details ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } key={ index } />
                        )
                    })
                }                

                {
                    GetDataPage('recover').map((elem, index)=>{
                        return(
                            <Route exact path={ "/recover=" + elem } element={ <Page_Recover ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } code={ elem } setLoading={ setLoading } /> } key={ index } />
                        )
                    })
                }
                {/* <Route exact path="/contact" element={ <Page_Contact ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } /> } /> */}
            </Routes>

            <Footer CkickPage={ CkickPage } setLoading={ setLoading } />
            
            <PopUP_Login CallbackSuccess={ CallbackSuccess } ReturnIndex={ ReturnIndex } CkickPage={ CkickPage } setLoading={ setLoading } />          

            <PopUP_Health setLoading={ setLoading } />
            <PopUP_ReturnResponse setLoading={ setLoading } />
            <PopUP_Text setLoading={ setLoading } />
            <PopUp_RecoverPassword setLoading={ setLoading } />
        </Router>
    );
}