import { useState, useEffect } from "react";

import './Recover.css';

import { useNavigate } from "react-router-dom";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

import { RecoverAltPassword, RecoverCheckedPassword } from "services/login";

export default function Page_Recover(props){
    
    const [ typeCode, setTypeCode ]     = useState(false);
    const [ password, setPassword ]     = useState('');
    const [ password_2, setPassword_2 ] = useState('');   

    const navigate = useNavigate(); 

    function SaveData(event){
        event.preventDefault();
        RecoverAltPassword(props.code, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        navigate("/login");
        SetListPag('page', 'login');

        SetModalData('ReturnResponse', { "page": "missingData", "text": "Senha alterada com sucesso!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        SetModalData('ReturnResponse', { "page": "erro", "text": "Ocorreu um erro, favor utilizar outro e-mail!" });
        SetModalState('ReturnResponse', true);
    }

    function CheckedCode(){  
        RecoverCheckedPassword(props.code, ()=>{ }, ()=>{ CodeInvalid() });
    }

    function CodeInvalid(){
        setTypeCode(true);
    }

    useEffect(()=>{
        CheckedCode();
    }, []);

    return(
        <form onSubmit={ SaveData } className="Page_Recover">
            <div className="container">
                {
                    typeCode ? 
                    <>
                        <div className="title space_link">Link inválido, gere um novo link</div>
                    </> :
                    <>                        
                        <div className="title">Recuperação de senha</div>
                        <div className="div_login">
                            <div className="div_input">
                                <div className="name_input">Senha*</div>
                                <div className="input">
                                    <input type="password" className="" onChange={ (e)=>{ setPassword(e.target.value) } } required />
                                </div>
                            </div>

                            <div className="div_input">
                                <div className="name_input">Confirmação da senha*</div>
                                <div className="input">
                                    <input type="password" className="" onChange={ (e)=>{ setPassword_2(e.target.value) } } required />
                                </div>
                            </div>

                            <div className="div_button">
                                {
                                    password == '' ? 
                                    <div className="button_login">
                                        Preenche os campos acima
                                    </div> :
                                    password != password_2 ? 
                                    <div className="button_login">
                                        As senhas devem ser iguais
                                    </div> : 
                                    <button className="button_login">
                                        Alterar
                                    </button>
                                }
                            </div>

                        </div>
                    </>
                }
            </div>
        </form>
    )
}