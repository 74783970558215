import { useEffect, useState } from "react";

import './Questions.css';

import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

import MakeAppointment from "components/MakeAppointment";

import { SvgArrow } from "components/SvgFile";

export default function ModelPage_Questions(props){

    const [ idioma, setIdioma ]   = useState(GetListPag('idioma'));
    const [ nameBtn, setNameBtn ] = useState(GetDataPage('config'));

    const [ listQuestions, setListQuestions ] = useState(InitialData());
    const [ textIdioma, setTextIdioma ] = useState([
        {
            "pt_pt": "Perguntas frequentes",
            "pt_br": "Perguntas frequentes",
            "en": "Common questions",
            "es": "Preguntas frecuentes"
        }
    ]);

    function InitialData(){
        const newData = GetDataPage('questions').filter(item => item.idioma == idioma);
        return newData
    }

    function OpenOrCloseQuestions(id, index, value){
        let accordion = document.getElementById(id);
        if(value == true){
            accordion.style.maxHeight = (accordion.scrollHeight + 68) + "px";
            accordion.style.padding   = "0px 34px";
        }else {
            accordion.style.maxHeight = null;
            setTimeout(() => {
            }, 460);
        }
        const newData            = [...listQuestions];
        newData[index]['status'] = value;
        setListQuestions(newData)
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
    }, []);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
        setListQuestions(InitialData());
    }, [idioma]);

    return(
        listQuestions.length > 0 ?
        <div className="ModelPage_Questions">
            <div className="container">
                <div className="title">
                    { textIdioma[0][idioma] }
                </div>
                <div className="list_questions">
                    {
                        listQuestions.map((elem, index)=>{
                            return(
                                <div className="div_questions" key={ index }>
                                    <div className="ques_title" onClick={ ()=>{ OpenOrCloseQuestions("questions_" + index, index, !elem.status) } }>
                                        <div className="">
                                            {
                                                elem.status ?
                                                <SvgArrow className="icons icon_open" color="#6B7D5C" /> : 
                                                <SvgArrow className="icons" color="#6B7D5C" />
                                            }
                                        </div>
                                        <div className="">
                                            { elem.question }
                                        </div>
                                    </div>
                                    <div className="ques_text" id={ "questions_" + index } dangerouslySetInnerHTML={ { __html: elem.response.replaceAll('&#34;', '"') } } />
                                </div>
                            )
                        })
                    }
                    <MakeAppointment setLoading={ props.setLoading } nameBtn={ nameBtn.name_btn } bgColor="color_1" textAlign={ ()=>{ } } />
                </div>
            </div>
            <div className="sheet sheet_1">
                <img alt="logotipo" src="./assets/question_01.png" className="sheet_img" />
            </div>
            <div className="sheet sheet_2">
                <img alt="logotipo" src="./assets/question_02.png" className="sheet_img" />
            </div>
            <div className="sheet sheet_3">
                <img alt="logotipo" src="./assets/question_03.png" className="sheet_img" />
            </div>
        </div>
        : <></>
    )
}