import { useState, useEffect } from "react";

import './Menu.css';

import { SvgMenu, SvgPurchase, SvgUser } from "components/SvgFile";

import { GetDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetPurchase, RegisterPurchase } from "interface/Purchase";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Link } from "react-router-dom";

export default function Menu(props){

    let idiomaDesktop = navigator.language || navigator.userLanguage;
    const [ page, setPage ]         = useState(GetListPag('page')); 
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ userData, setUserData ] = useState(GetUserData('user'));

    const [ dataMenu, setDataMenu ] = useState(GetDataPage('config'));
    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));
    const [ qtdPurch, setQtdPurch ] = useState(CountPurchase());    

    const [ statusIdioma, setStatusIdioma ] = useState(false);
    const [ statusMenu, setStatusMenu ]     = useState(false);
    
    const [ url, setUrl ] = useState(window.location.href.split("/"));

    const [ nameUser, setNameUser ] = useState(ShowNameUser());

    function OpenIdioma(value){
        let accordion = document.getElementById('div_idioma');
        if(value == true){
            accordion.style.maxHeight = accordion.scrollHeight + "px";
        }else {
            accordion.style.maxHeight = null;
        }
        setStatusIdioma(value);
    }

    function CountPurchase(){
        const newData = [];
        GetPurchase('purchase').map((elem, index)=>{
            newData.push(elem.qtd);
        });
        return newData.reduce((accumulator, qtd) => accumulator + qtd, 0);
    }
    
    function CloseIdioma(value){
        let accordion = document.getElementById('div_idioma');        
        accordion.style.maxHeight = null;

        props.setLoading(true);
        SetListPag('idioma', value);
        setTimeout(() => {
            props.setLoading(false);
        }, 1200);
        setStatusIdioma(false);
    }

    function ShowIdioma(){
        if(dataMenu.pt_br == false && dataMenu.en == false && dataMenu.es == false){
            return "";
        }else {         
            return(
                <div className="div_idioma">
                    <img alt="idioma" src={ "./assets/idioma_" + idioma + ".png" } className="img_idioma" onClick={ ()=>{ OpenIdioma(!statusMenu) } } />
                    <div id="div_idioma" className="list_opt">
                        {
                            dataMenu.pt_pt ? 
                            idioma == "pt_pt" ? null : <img alt="idioma" src="./assets/idioma_pt_pt.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('pt_pt') } } />
                            : null
                        }
                        {
                            dataMenu.pt_br ? 
                            idioma == "pt_br" ? null : <img alt="idioma" src="./assets/idioma_pt_br.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('pt_br') } } />
                            : null
                        }
                        {
                            dataMenu.en ? 
                            idioma == "en" ? null : <img alt="idioma" src="./assets/idioma_en.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('en') } } />
                            : null
                        }
                        {
                            dataMenu.es ? 
                            idioma == "es" ? null : <img alt="idioma" src="./assets/idioma_es.png" className="img_idioma" onClick={ ()=>{ CloseIdioma('es') } } />
                            : null
                        }
                    </div>
                </div>
            )
        }
    }

    function OpenMenu(value){
        let accordion = document.getElementById('div_opt_menu');
        if(value == true){
            accordion.style.maxHeight = accordion.scrollHeight + "px";
        }else {
            accordion.style.maxHeight = null;
        }
        setStatusMenu(value);
    }

    function ShowNameUser(){
        if(userData){
            if(Object.keys(userData).length > 0){
                let infName = userData.name.split(" ")
                return (
                    <div className={ GetListPag('page') == "index" ? "name_user color_1" : "name_user color_2" }>Bem vindo { infName[0] }</div>
                )
            }
        }
        return '';
    }

    useEffect(()=>{
        RegisterListPag('page', setPage);
        RegisterListPag('idioma', setIdioma);
        RegisterUserData('user', setUserData);

        RegisterPurchase('purchase', setPurchase);
    }, []);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'))
    }, [idioma]);

    useEffect(()=>{
        setPurchase(GetPurchase('purchase'));
        setQtdPurch(CountPurchase());
        setNameUser(ShowNameUser());
    }, [purchase]);

    useEffect(()=>{
        setUserData(GetUserData('user'));
        setNameUser(ShowNameUser());
    }, [userData]);

    useEffect(()=>{
        setNameUser(ShowNameUser());
    }, [page]);
    
    return(
        <div className="Menu" id="div_menu">
            <div className={ page == "index" ? "div_contact" : "div_contact color_greed" }>
                <div className="">{ dataMenu.menu_text }</div>
                <div className="">
                    <a href={ "tel:" + dataMenu.menu_contact }>{ dataMenu.menu_contact }</a>
                </div>
            </div>
            
            <Link to="/" className="div_logo" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'index'); props.ReturnIndex(); } }>
                <img alt="logotipo" src={ page == "index" ? "./assets/footer_01.png" : "./assets/footer_03.png" } className="logo" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'index'); props.ReturnIndex() } } />
            </Link>

            <div className="div_opt">
                { 
                    nameUser 
                }  
                <Link className="purchase show_login" to="/login" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'login'); props.ReturnIndex(); } }>              
                    {
                        GetListPag('page') == "index" ?
                        <SvgUser className="icons" color="#E7E3CE" /> : 
                        <SvgUser className="icons" color="#6B7D5C" />
                    }
                </Link>
                {
                    ShowIdioma()
                }
                {
                    purchase.length > 0 ? 
                    <Link className="purchase" to="/purchase" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'purchase'); props.ReturnIndex(); } }>                    
                        <div className="qtd">
                            { qtdPurch }
                        </div>
                        {
                            GetListPag('page') == "index" ?
                            <SvgPurchase className="icons" color="#E7E3CE" /> : 
                            <SvgPurchase className="icons" color="#6B7D5C" />
                        }
                    </Link>
                    : null
                } 
                <div className="" onClick={ ()=>{ OpenMenu(!statusMenu) } }>
                    {
                        page == "index" ?
                        <SvgMenu className="icons" color="#E7E3CE" /> : 
                        <SvgMenu className="icons" color="#6B7D5C" />
                    }
                </div>
                <div className="div_opt_menu" id="div_opt_menu">
                    <Link className="name_menu" to="/" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'index'); setPage('index'); props.ReturnIndex(); } }>
                        Home
                    </Link>
                    
                    <Link className="name_menu" to="/about" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'about'); setPage('about'); props.ReturnIndex(); } }>
                        Quem somos
                    </Link>
                    
                    <Link className="name_menu" to="/whatWeDo" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'whatWeDo'); setPage('whatWeDo'); props.ReturnIndex(); } }>
                        O que raio fazemos
                    </Link>
                    {
                        idiomaDesktop == "pt-PT" || idioma == 'pt_pt' ?
                        <Link className="name_menu" to="/product" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'product'); setPage('product'); props.ReturnIndex(); } }>
                            Produtos
                        </Link>
                        : null
                    }
                    
                    {/* 
                    <Link className="name_menu" to="/contact" onClick={ ()=>{ OpenMenu(false); SetListPag('page', 'contact'); setPage('contact'); props.ReturnIndex(); } }>
                        Contacto
                    </Link> 
                    */}
                    <a className="name_menu" href="#contact" onClick={ ()=>{ OpenMenu(false); } }>
                        Contacto
                    </a>
                </div>
            </div>
        </div>
    )
}