import { useState, useEffect } from "react";

import './Register.css';

import { Link, useNavigate } from "react-router-dom";

import { Register } from "services/login";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Register(props){

    const [ name, setName ]                 = useState('');
    const [ email, setEmail ]               = useState('');
    const [ contact, setContact ]           = useState('');
    const [ household, setHousehold ]       = useState('');
    const [ postalCode, setPostalCode ]     = useState('');
    const [ location, setLocation ]         = useState('');
    const [ district, setDistrict ]         = useState('');
    const [ password, setPassword ]         = useState('');
    const [ password_2, setPassword_2 ]     = useState('');
    const [ msgError, setMsgError ]         = useState('');

    const navigate = useNavigate(); 

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Register('register', 0, name, email, contact, household, postalCode, location, district, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        navigate("/login");
        SetListPag('page', 'login');
        
        props.setLoading(false);
        props.CallbackSuccess(true);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "Cadastro realizado com sucesso!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "E-mail inválido, favor utilizar outro e-mail!" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <form onSubmit={ SaveData } className="Page_Register">
            <div className="container">
                <div className="title">Registar no site</div>
                <div className="div_login">
                    <div className="div_row">
                        <div className="div_input">
                            <div className="name_input">Nome*</div>
                            <div className="input">
                                <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } maxLength="80" required />
                            </div>
                        </div>
                        <div className="div_input">
                            <div className="name_input">Contato*</div>
                            <div className="input">
                                <input type="text" className="" onChange={ (e)=>{ setContact(e.target.value) } } maxLength="80" required />
                            </div>
                        </div>
                        <div className="div_input">
                            <div className="name_input">E-mail*</div>
                            <div className="input">
                                <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } maxLength="80" required />
                            </div>
                        </div>
                    </div>
                    
                    <div className="div_row">
                        <div className="div_input">
                            <div className="name_input">Morada*</div>
                            <div className="input">
                                <input type="text" className="" onChange={ (e)=>{ setHousehold(e.target.value) } } maxLength="120" required />
                            </div>
                        </div>
                        <div className="div_input">
                            <div className="name_input">Código postal*</div>
                            <div className="input">
                                <input type="text" className="" onChange={ (e)=>{ setPostalCode(e.target.value) } } maxLength="120" required />
                            </div>
                        </div>
                        <div className="div_input">
                            <div className="name_input">Localidade*</div>
                            <div className="input">
                                <input type="text" className="" onChange={ (e)=>{ setLocation(e.target.value) } } maxLength="120" required />
                            </div>
                        </div>
                    </div>
                    
                    <div className="div_row">
                        <div className="div_input">
                            <div className="name_input">Senha*</div>
                            <div className="input">
                                <input type="password" className="" onChange={ (e)=>{ setPassword(e.target.value) } } required />
                            </div>
                        </div>
                        <div className="div_input">
                            <div className="name_input">Confirmação da senha*</div>
                            <div className="input">
                                <input type="password" className="" onChange={ (e)=>{ setPassword_2(e.target.value) } } required />
                            </div>
                        </div>
                    </div>

                    <div className="div_button">
                        {
                            password == '' || password_2 == '' ? 
                            <div className="button_login">
                                Por favor preencher os campos obrigatórios
                            </div> :
                            <>
                                {
                                    password != password_2 ? 
                                    <div className="button_login">
                                        As senhas devem ser iguais
                                    </div> : 
                                    <button className="button_login">
                                        Registar
                                    </button>
                                }
                            </>
                        }
                    </div> 

                    <div className="div_recovery_access">                        
                        <Link className="new_register" to="/login" onClick={ ()=>{ SetListPag('page', 'login'); props.ReturnIndex(); } }>
                            Voltar para o login
                        </Link>
                    </div>

                </div>
            </div>
        </form>
    )
}