import React, { useState, useEffect } from "react";

import './Login.css';

import { SvgClose } from "components/SvgFile";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Access, Register } from "services/login";

export default function PopUP_Login(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ typePage, setTypePage ] = useState('login');

    /* login */
    const [ login_email, setLogin_Email ]       = useState('');
    const [ login_password, setLogin_Password ] = useState('');
    const [ login_msgError, setLogin_MsgError ] = useState('');
    /* end */

    /* register */
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ contact, setContact ] = useState('');
    const [ household, setHousehold ] = useState('');
    const [ postalCode, setPostalCode ] = useState('');
    const [ location, setLocation ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ password_2, setPassword_2 ] = useState('');
    /* end */

    function Logar(event){
        event.preventDefault();
        props.setLoading(true);
        Access('logar', login_email, login_password, ()=>{ Logar_CallbackSuccess() }, ()=>{ Logar_CallbackError() });
    }

    function Logar_CallbackSuccess(){
        props.setLoading(false);
        props.CallbackSuccess(true);
        ClosePopUp();
    }

    function Logar_CallbackError(){
        props.setLoading(false);
        props.CallbackSuccess(false);
        setLogin_MsgError('E-mail ou senha incorreta!');
    }
    
    function RegisterForm(eventForm){
        eventForm.preventDefault();
        props.setLoading(true);
        Register('register', 0, name, email, contact, household, postalCode, location, district, password, ()=>{ Register_CallbackSuccess() }, ()=>{ Register_CallbackError() });
    }

    function Register_CallbackSuccess(){
        ClosePopUp();
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "missingData", "text": "Registo realizado com sucesso!" });
        SetModalState('ReturnResponse', true);
    }

    function Register_CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "E-mail inválido, favor utilizar outro e-mail!" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        SetModalState('ReturnLogin', false);
    }

    useEffect(()=>{
        RegisterModalData('ReturnLogin', setModaldata);
        RegisterModalObserver('ReturnLogin', setShowPopUp);
    }, []);

    return(
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className={ "all ReturnLogin page_" + typePage }>
                        <div className="div_data">
                            <div className="content">
                                <div className="div_title">
                                    <div className="title_popup"></div>
                                    <div className="">
                                        <a onClick={ ()=>{ ClosePopUp() } }>
                                            <SvgClose color="#F00000" className="icons" />
                                        </a>
                                    </div>
                                </div>
                                {
                                    typePage == "login" ? 
                                    <form onSubmit={ Logar } className="Page_Login">
                                        <div className="container">
                                            <div className="title">Logar no site</div>
                                            <div className="div_login">
                                                <div className="div_input">
                                                    <div className="name_input">Login</div>
                                                    <div className="input">
                                                        <input type="email" className="" onChange={ (e)=>{ setLogin_Email(e.target.value) } } required />
                                                    </div>
                                                </div>
                                                <div className="div_input">
                                                    <div className="name_input">Senha</div>
                                                    <div className="input">
                                                        <input type="password" className="" onChange={ (e)=>{ setLogin_Password(e.target.value) } } required />
                                                    </div>
                                                </div>
                                                {
                                                    login_msgError == "" ? null : <div className="msg_error">{ login_msgError }</div>
                                                }
                                                <div className="div_button">
                                                    <button className="button_login">Login</button>
                                                </div> 

                                                <div className="div_recovery_access">
                                                    <div className="new_register" onClick={ ()=>{ setTypePage('register') } }>
                                                        Cadastrar
                                                    </div>

                                                    <div className="new_register recovery" onClick={ ()=>{ SetModalState('RecoverPassword', true) } }>Recuperar senha</div>
                                                </div>

                                            </div>
                                        </div>
                                    </form> 
                                    :
                                    <form onSubmit={ RegisterForm } className="Page_Register">
                                        <div className="container">
                                            <div className="title">Registar no site</div>
                                            <div className="div_login">
                                                <div className="div_row">
                                                    <div className="div_input">
                                                        <div className="name_input">Nome*</div>
                                                        <div className="input">
                                                            <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } maxLength="80" required />
                                                        </div>
                                                    </div>
                                                    <div className="div_input">
                                                        <div className="name_input">E-mail*</div>
                                                        <div className="input">
                                                            <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } maxLength="80" required />
                                                        </div>
                                                    </div>
                                                    <div className="div_input">
                                                        <div className="name_input">Contato*</div>
                                                        <div className="input">
                                                            <input type="text" className="" onChange={ (e)=>{ setContact(e.target.value) } } maxLength="80" required />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="div_row">
                                                    <div className="div_input">
                                                        <div className="name_input">Morada*</div>
                                                        <div className="input">
                                                            <input type="text" className="" onChange={ (e)=>{ setHousehold(e.target.value) } } maxLength="120" required />
                                                        </div>
                                                    </div>
                                                    <div className="div_input">
                                                        <div className="name_input">Código postal*</div>
                                                        <div className="input">
                                                            <input type="text" className="" onChange={ (e)=>{ setPostalCode(e.target.value) } } maxLength="120" required />
                                                        </div>
                                                    </div>
                                                    <div className="div_input">
                                                        <div className="name_input">Localidade*</div>
                                                        <div className="input">
                                                            <input type="text" className="" onChange={ (e)=>{ setLocation(e.target.value) } } maxLength="120" required />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="div_row">
                                                    <div className="div_input">
                                                        <div className="name_input">Senha*</div>
                                                        <div className="input">
                                                            <input type="password" className="" onChange={ (e)=>{ setPassword(e.target.value) } } required />
                                                        </div>
                                                    </div>
                                                    <div className="div_input">
                                                        <div className="name_input">Confirmação da senha*</div>
                                                        <div className="input">
                                                            <input type="password" className="" onChange={ (e)=>{ setPassword_2(e.target.value) } } required />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="div_button">
                                                    {
                                                        password == '' || password_2 == '' ? 
                                                        <div className="button_login">
                                                            Por favor preencher os campos obrigatórios
                                                        </div> :
                                                        <>
                                                            {
                                                                password != password_2 ? 
                                                                <div className="button_login">
                                                                    As senhas devem ser iguais
                                                                </div> : 
                                                                <button className="button_login">
                                                                    Registar
                                                                </button>
                                                            }
                                                        </>
                                                    }
                                                </div> 

                                                <div className="div_recovery_access">                        
                                                    <div className="new_register" onClick={ ()=>{ setTypePage('login') } }>
                                                        Voltar para o login
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </form>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    )
};
