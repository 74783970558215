import Axios from 'axios';
import { cookie_site_login, cookie_site_password, cookiesRegister } from 'fixedData';
import { SetUserData } from 'interface/Users';

import Cookies from 'universal-cookie';

export function Access(type, reg_email, reg_pass, CallbackSuccess, CallbackError){ 
    const cookies = new Cookies(); 
    const data    = new FormData();

    data.append('type', type);
    data.append('email', reg_email);
    data.append('password', reg_pass);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data.type) {
            case "certo":
                    CallbackSuccess();

                    // Obtém a data atual
                    const day = new Date();

                    // Adiciona 3 dias
                    const add3Days = new Date();
                    add3Days.setDate(day.getDate() + 3);

                    cookies.set(cookie_site_password, response.data.user.pass, { path: '/', expires: add3Days }, cookiesRegister);
                    cookies.set(cookie_site_login, response.data.user.email, { path: '/', expires: add3Days }, cookiesRegister);
 
                    SetUserData('id', response.data.user.id);
                    SetUserData('user', response.data.user);
                break;

            default:
                    CallbackError();
                    cookies.remove(cookie_site_password, "", '/', cookiesRegister);
                    cookies.remove(cookie_site_login, "", '/', cookiesRegister);
                break;
        }
    }).catch((error)=>{
        CallbackError();        
        cookies.remove(cookie_site_password, "", '/', cookiesRegister);
        cookies.remove(cookie_site_login, "", '/', cookiesRegister);
    })
}

export function Register(type, idPage, name, email, contact, household, postalCode, location, district, password, CallbackSuccess, CallbackError){ 
    const cookies = new Cookies(); 
    const data    = new FormData();
    data.append('type', type);

    data.append('idPage', idPage);
    data.append('name', name);
    data.append('email', email);
    data.append('contact', contact);
    data.append('household', household);
    data.append('postal_code', postalCode);
    data.append('location', location);
    data.append('district', district);
    data.append('password', password);
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data.type) {
            case "certo":
                    CallbackSuccess();
                    // Obtém a data atual
                    const day = new Date();

                    // Adiciona 3 dias
                    const add3Days = new Date();
                    add3Days.setDate(day.getDate() + 3);

                    if(response.data.user.pass !=''){
                        cookies.set(cookie_site_password, response.data.user.pass, { path: '/', expires: add3Days }, cookiesRegister);
                    }
                    cookies.set(cookie_site_login, response.data.user.email, { path: '/', expires: add3Days }, cookiesRegister);
 
                    SetUserData('user', response.data.user);
                break;

            default:
                    CallbackError();
                    cookies.remove(cookie_site_password, "", '/', cookiesRegister);
                    cookies.remove(cookie_site_login, "", '/', cookiesRegister);
                break;
        }
    }).catch((error)=>{
        CallbackError();        
        cookies.remove(cookie_site_password, "", '/', cookiesRegister);
        cookies.remove(cookie_site_login, "", '/', cookiesRegister);
    })
}

export function RecoverEmail(email, CallbackSuccess, CallbackError_Send, CallbackError_NotEmail, CallbackError){
    const data = new FormData();    
    data.append('type', 'recover_email');
    data.append('email', email);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/recover_pass.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            case "Email não enviado":
                    CallbackError_Send();
                break;
            case "E-mail não encontrado":
                    CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                    CallbackError();
                break;
        }
    }).catch((error)=>{
        CallbackError();
    })
}

export function RecoverAltPassword(code, password, CallbackSuccess, CallbackError){
    const data = new FormData();    
    data.append('type', 'alt_password');
    data.append('code', code);
    data.append('password', password);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data) {
            case "ok!":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }
    }).catch((error)=>{
        CallbackError();
    })
}

export function RecoverCheckedPassword(code, CallbackSuccess, CallbackError){
    const data = new FormData();    
    data.append('type', 'checked_password');
    data.append('code', code);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/login.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        switch (response.data) {
            case "ok!":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }
    }).catch((error)=>{
        CallbackError();
    })
}

export function GeneratorLinkPayment(idUser, listPurchase, CallbackSuccess, CallbackError){
    const data = new FormData();    
    data.append('type', 'generator_payment');
    
    data.append('idUser', idUser);

    listPurchase.forEach((elem, index) => {
        data.append('id_product[]', elem.data.id);
        data.append('qtd[]', elem.qtd);
        data.append('flavor[]', elem.flavor);
        data.append('cash[]', elem.data.cash);
        data.append('name[]', elem.data.name);
        data.append('file[]', elem.data.file_single);
        data.append('text[]', elem.data.text);
    });
    
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/generator_payment.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })   
    .then(response => {
        console.log(response.data);
        
        switch (response.data.type) {
            case "ok":
                    CallbackSuccess(response.data);
                break;
            default:
                    CallbackError();
                break;
        }
    }).catch((error)=>{
        CallbackError();
    })
}