import React, { useState, useEffect } from "react";

import './ShowPurchase.css';

import { SvgClose } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Link } from "react-router-dom";

export default function PopUP_ShowPurchase(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ statusProduct, setStatusProduct ] = useState('');
    const [ date, setDate ] = useState('');
    const [ cashTotal, setCashTotal ] = useState('');
    const [ list, setList ] = useState([]);

    function ClosePopUp(){
        setStatusProduct('');
        setDate('');
        setCashTotal('');
        setList([]);
        SetModalState('ShowPurchase', false);
    }

    useEffect(()=>{
        RegisterModalData('ShowPurchase', setModaldata);
        RegisterModalObserver('ShowPurchase', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setStatusProduct(modalData.statusProduct);
            setDate(modalData.date);
            setCashTotal(modalData.cash_total);
            setList(modalData.list);
        }
    }, [showPopUp]);

    return(
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all ShowPurchase">
                        <div className="div_data">
                            <div className="content">
                                <div className="div_title">
                                    <div className="title_popup">Lista dos produtos</div>
                                    <div className="">
                                        <a onClick={ ()=>{ ClosePopUp() } }>
                                            <SvgClose color="#F00000" className="icons" />
                                        </a>
                                    </div>
                                </div>
                                <div className="details_product">
                                    <div className="inf_product">
                                        <div className="">Pedido realizado na data: { date }</div>
                                        <div className="">Valor total da compra: { cashTotal }</div>
                                        <div className="">Pagamento: { statusProduct }</div>
                                    </div>
                                    <div className="list">
                                        {
                                            list.map((elem, index)=>{
                                                return(
                                                    <div className="product_details" key={ index }>
                                                        <div className="product_name">
                                                            <div className="product_show_inf">Produto: { elem.name }</div>
                                                            {
                                                                elem.flavor !='' ? 
                                                                    <div className="product_show_inf">Sabor: { elem.flavor }</div>
                                                                : null
                                                            }
                                                            <div className="product_show_inf">
                                                                Valor unitário €: { elem.cash } | Qtd: { elem.qtd } | Total: { elem.total }
                                                            </div>

                                                            <Link className="div_btn" to={ "/" + elem.url } onClick={ ()=>{ SetListPag('page', 'product_details'); props.ReturnIndex(); } }>
                                                                <div className="btn">Visualizar detalhes do produto</div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    )
};
