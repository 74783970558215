
let DataPage = {
    "site"              : [],
    "config"            : {},
    "recover"           : [],

    "products"          : [],
    "products_text"     : [],
    "products_category" : [],

    "purchase"          : [],
    "questions"         : [],
    "questionnaire"     : [],
    "unavailable_time"  : []
};

let NotifyDataPage = {
    "site"              : [],
    "config"            : [],
    "recover"           : [],

    "products"          : [],
    "products_text"     : [],
    "products_category" : [],

    "purchase"          : [],
    "questions"         : [],
    "questionnaire"     : [],
    "unavailable_time"  : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["site"].forEach(element => {              element(value['site']); });
    NotifyDataPage["config"].forEach(element => {            element(value['config']); });
    NotifyDataPage["recover"].forEach(element => {           element(value['recover']); });
    NotifyDataPage["products"].forEach(element => {          element(value['products']); });
    NotifyDataPage["purchase"].forEach(element => {          element(value['purchase']); });
    NotifyDataPage["questions"].forEach(element => {         element(value['questions']); });
    NotifyDataPage["products_text"].forEach(element => {     element(value['products_text']); });
    NotifyDataPage["questionnaire"].forEach(element => {     element(value['questionnaire']); });
    NotifyDataPage["unavailable_time"].forEach(element => {  element(value['unavailable_time']); });
    NotifyDataPage["products_category"].forEach(element => { element(value['products_category']); });
}

export function SetListDataSingle(key, value) {
    
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
