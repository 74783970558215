import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';

import App from './App';

import Axios from 'axios';

import { SetListPag } from 'interface/Page';
import { SetListData } from 'interface/Data';

const root = ReactDOM.createRoot(document.getElementById('root'));
let idiomaDesktop = navigator.language || navigator.userLanguage;

const data = new FormData();
data.append('current_page', 'show_data');

function ShowIdiomaLocal(pt_br, en, es){
    switch (idiomaDesktop) {
        case "pt-PT": 
                SetListPag('idioma', 'pt_pt'); 
            break;
        case "pt-BR": 
                if(pt_br){
                    SetListPag('idioma', 'pt_br'); 
                }else {
                    SetListPag('idioma', 'pt_pt'); 
                }
            break;

        case "en":   
                if(en){
                    SetListPag('idioma', 'en'); 
                }else {
                    SetListPag('idioma', 'pt_pt'); 
                }    
            break;

        case "es":    
                if(es){
                    SetListPag('idioma', 'es'); 
                }else {
                    SetListPag('idioma', 'pt_pt'); 
                }      
            break; 

        default:     
                SetListPag('idioma', 'pt_pt'); 
            break;
    }
}

Axios({
    url     : process.env.REACT_APP_API_URL + 'php/list_data.php',
    mode    : 'no-cors',
    method  : 'POST',
    data    : data
})
.then(response => {
  
    ShowIdiomaLocal(response.data.config.pt_br, response.data.config.en, response.data.config.es);
    SetListData(response.data);

    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    );
});
