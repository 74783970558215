// Connection type
export const connectionPage = '.';

// Cookies type
// export const cookiesRegister = './';
export const cookiesRegister = 'famura.eu/';

// Cookies name
export const cookie_site    = 'famura_site';
export const cookie_site_id = 'famura_site_id';

export const cookie_site_login    = 'famura_site_login';
export const cookie_site_password = 'famura_site_password';

// week
export const listWeek = [
    {
        "value": "monday",
        "label": "Segunda-feira"
    },
    {
        "value": "tuesday",
        "label": "Terça-feira"
    },
    {
        "value": "wednesday",
        "label": "Quarta-feira"
    },
    {
        "value": "thursday",
        "label": "Quinta-feira"
    },
    {
        "value": "friday",
        "label": "Sexta-feira"
    },
    {
        "value": "saturday",
        "label": "Sábado"
    }   
]
// hour
export const listHour = [
    "08:00", "09:00", "10:00", "11:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00"
]