import { useState, useEffect, useRef } from "react";

import Cookies from 'universal-cookie';

import './Purchase.css';

import { startCheckout } from '@easypaypt/checkout-sdk';

import { cookie_site_login, cookie_site_password } from "fixedData";

import { SetListPag } from "interface/Page";
import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetPurchase, SetPurchaseSingle } from "interface/Purchase";

import { SvgDelete } from "components/SvgFile";

import { Link } from "react-router-dom";

import { Access, GeneratorLinkPayment } from "services/login";

import PopUP_Login from "components/PopUp/Login";
import PopUp_Payment from "components/PopUp/Payment";

export default function Page_Purchase(props){

    const cookies = new Cookies();
    const showText = useRef();
    const [ showHideText, setShowHideText ] = useState(false);

    const [ limitFee, setLimitFee ] = useState(GetDataPage('products_text')[0].limit_fee);
    const [ shippingFee, setShippingFee ] = useState(GetDataPage('products_text')[0].shipping_fee);
    const [ dataUser, setDataUser ] = useState(GetUserData('user'));
    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));
    const [ product, setProduct ] = useState(GetDataPage('products'));
    
    const [ listPurchase, setListPurchase ] = useState(purchase);
    
    function HandleData(type, index, qtdPur, id){
        const newData = [...listPurchase];
        if(qtdPur == 0){
            newData.splice(index, 1);
            SetPurchaseSingle('remuve', id, 0);
        }else {            
            SetPurchaseSingle('edit', id, qtdPur);
            newData[index].qtd = qtdPur;
        }
        setListPurchase(newData);
    }

    function RemuveData(index, id){
        const newData = [...listPurchase];
        newData.splice(index, 1);
        setListPurchase(newData);
        SetPurchaseSingle('remuve', id, 0);
    }

    function ShowCash(){
        let cashTotal = 0.00;
        purchase.map((elem, index)=>{
            let cashProduct = 0.00;
            if(elem.data.cash_promotional !=''){
                let sep_ = elem.data.cash_promotional.replaceAll('.', '');
                let sep_1 = sep_.replaceAll(',', '.');
                cashTotal += elem.qtd * sep_1;

            }else {
                let sep_ = elem.data.cash.replaceAll('.', '');
                let sep_1 = sep_.replaceAll(',', '.');
                cashTotal += elem.qtd * sep_1;
            }
        })

        let formatter = new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        let shippingFee_ = shippingFee.replaceAll('.', '');
        shippingFee_ = shippingFee_.replaceAll(',', '.');
        
        let limitFee_ = limitFee.replaceAll('.', '');
        limitFee_ = limitFee_.replaceAll(',', '.');

        if(parseFloat(cashTotal) <= parseFloat(limitFee_)){
            cashTotal = parseFloat(cashTotal) + parseFloat(shippingFee_);
        }else {
            cashTotal = parseFloat(cashTotal);
        }
        let showCashTotal = formatter.format(cashTotal); 
        return showCashTotal;
    }

    function CheckedCash(){
        let cashTotal = ShowCash();
        
        let limitFee_ = limitFee.replaceAll('.', '');
        limitFee_ = limitFee_.replaceAll(',', '.');
        
        let shippingFee_ = shippingFee.replaceAll('.', '');
        shippingFee_ = shippingFee_.replaceAll(',', '.');

        let formatter = new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        if(parseFloat(cashTotal) <= parseFloat(limitFee_)){
            let showCashTotal = formatter.format(shippingFee_); 
            return showCashTotal;
        }else {
            return "Sem custo de envio";
        }
    }

    function ClosePurchase(){
        props.setLoading(true);
        if(cookies.get(cookie_site_login) || cookies.get(cookie_site_password)){
            let return_email = cookies.get(cookie_site_login);
            let return_pass  = cookies.get(cookie_site_password);
            Access('return', return_email, return_pass, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            CallbackError();
        }
    }

    function CallbackSuccess(){
        if(GetUserData('id') !=0){
            GeneratorLinkPayment(GetUserData('id'), listPurchase, Return_CallbackSuccess, ()=>{ Return_CallbackError() });
        }else {
            let saveFunc = setInterval(() => {
                if(GetUserData('id') !=0){
                    GeneratorLinkPayment(GetUserData('id'), listPurchase, Return_CallbackSuccess, ()=>{ Return_CallbackError() });
                    clearInterval(saveFunc);
                }
            }, 600);
        }
    }

    function Return_CallbackSuccess(data){
        props.setLoading(false);
        SetModalData('Payment', { "url": data.url, "id": data.id, "dateSaved": data.dateSaved });
        SetModalState('Payment', true);
    }

    function Return_CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "Erro ao registrar, tente novamente!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalState('ReturnLogin', true);
    }

    useEffect(()=>{
        RegisterDataPage('products', setProduct);
    }, []);

    useEffect(()=>{
        setListPurchase(purchase);
    }, [purchase]);

    return(
        <div className="Page_Purchase">
            <div className="show_product">
                <div className="list_product">
                    <div className="container">
                        <div className="title">O teu carrinho:</div>
                        <div className="div_product">
                            {
                                listPurchase.length > 0 ?
                                listPurchase.map((elem, index)=>{
                                    let qtdProduct = 0;
                                    if(elem.flavor == ''){
                                        qtdProduct = elem.data.qtd
                                    }else {
                                        let infProduct = elem.data.flavor.find(item => item.name_flavor == elem.flavor);
                                        qtdProduct = infProduct.qtd
                                    }
                                    return(
                                        <div className="show_data_product" key={ index }>
                                            <div className="div_img">                                                
                                                <img alt="img" src={ elem.data.file == "" ? "./assets/not_file.jpg" : elem.data.file } className={ elem.data.file == "" ? "img_product not_file" : "img_product" } />
                                            </div>
                                            <div className="div_data">
                                                <div className="div_title">{ elem.data.name }</div>
                                                <div className="div_text" dangerouslySetInnerHTML={ { __html: showHideText ? elem.data.text.replaceAll('&#34;', '"') : elem.data.text.replaceAll('&#34;', '"').substring(0, 300) + '...' } } />

                                                <div className="span_show_text" onClick={ ()=>{ setShowHideText(!showHideText) } }>
                                                    {
                                                        showHideText ? "Clique aqui para ocultar a descrição do produto": "Clique aqui para visualizar a descrição do produto completa"
                                                    }
                                                </div>

                                                <div className="show_cash_qtd">
                                                    <div className="div_cash">
                                                        Valor 
                                                        {
                                                            elem.data.cash_promotional == "" ? " € " + elem.data.cash :
                                                            <>
                                                                <span className="promotional"> € { elem.data.cash }</span> por € { elem.data.cash_promotional }
                                                            </>
                                                        }
                                                    </div>
                                                    {
                                                        elem.flavor !='' ?
                                                        <div className="div_qtd">
                                                            <div className="">Sabor: { elem.flavor }</div>
                                                        </div>
                                                        : null
                                                    }
                                                    <div className="div_qtd">
                                                        <div className="">Quantidade:</div>
                                                        <div className="show_opt_qtd">
                                                            <div className="add_or_remuve" onClick={ ()=>{ HandleData('remuve', index, elem.qtd - 1, elem.id) } }>-</div>
                                                            <div className="qtd">{ elem.qtd }</div>
                                                            {
                                                                elem.qtd == qtdProduct ?
                                                                <div className="add_or_remuve not_add">+</div>
                                                                :
                                                                <div className="add_or_remuve" onClick={ ()=>{ HandleData('edit', index, elem.qtd + 1, elem.id) } }>+</div>
                                                            }
                                                        </div>
                                                        {/* {
                                                            elem.qtd == qtdProduct ? "Estoque máximo atingido" : ""
                                                        } */}
                                                    </div>
                                                </div>
                                                <div className="remuve_data" onClick={ ()=>{ RemuveData(index, elem.id) } }>
                                                    Remover produto
                                                    <SvgDelete className="icons" color="#f00000" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div className="space_">
                                    Lista de compra vazia...
                                </div>
                            }
                        </div>
                        <div id="easypay-checkout"></div>
                    </div>
                </div>

                <div className="container">
                    <div className="div_show_cash_end">
                        <div className="shipping_fee">Portes de envio: { CheckedCash() }</div>
                        <div className="show_cash">Total: { ShowCash() }</div>
                    </div>
                </div>

                <div className="container">
                    {
                        listPurchase.length > 0 ? 
                        <div className="div_btn" onClick={ ()=>{ ClosePurchase() } }>
                            Finalizar compra
                        </div> :
                        <Link className="div_btn" to="/product" onClick={ ()=>{ SetListPag('page', 'product'); props.ReturnIndex(); } }>
                            Visualizar lista de produtos
                        </Link>
                    } 
                </div> 
            </div>

            <PopUp_Payment setLoading={ props.setLoading } ReturnIndex={ props.ReturnIndex } />
        </div>
    )
}