import { useState, useEffect } from "react";

import './CardIcons.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

import MakeAppointment from "components/MakeAppointment";

export default function ModelPage_CardIcons(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newOrigin = GetDataPage('site').filter(item => item.origin_ == props.origin);
        const newData   = newOrigin.filter(item => item.idioma == idioma);
        return newData
    }

    function TypeColor(value){
        switch (value) {
            case "#F5F5F5": case "#E7E3CE":
                return "color_1";

            case "#6B7D5C":
                return "color_2";
        
            default:
                return "color_1";
        }
    }

    useEffect(()=>{
        if(props.index != 0){            
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }
    }, []);

    return(
        <div className={ "ModelPage_CardIcons " + spaceTop + " " + spaceBottom }>
            <div className="container">
                {
                    props.data.contents.map((elem, index)=>{
                        return(
                            <div className="card_image" key={ index }>
                                <div className="div_img">
                                    <img alt={ "img_" + index } src={ elem.file } className="img" />
                                </div>
                                <div className="text" dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                            </div>
                        )
                    })
                }
                {
                    props.data.show_btn == "Não" ? null : <MakeAppointment setLoading={ props.setLoading } nameBtn={ props.data.name_btn } bgColor={ TypeColor(props.data.color) } textAlign={ ()=>{ } } />
                }
            </div>
        </div>
    )
}