import { useState, useEffect } from "react";

import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

import ModelPage_Home from "components/ModelPage/Home";
import ModelPage_Arrow from "components/ModelPage/Arrow";
import ModelPage_Questions from "components/ModelPage/Questions";
import ModelPage_TextTitle from "components/ModelPage/TextTitle";
import ModelPage_CardPhoto from "components/ModelPage/CardPhoto";
import ModelPage_TextPhoto from "components/ModelPage/TextPhoto";
import ModelPage_CardIcons from "components/ModelPage/CardIcons";
import ModelPage_TextSimple from "components/ModelPage/TextSimple";

export default function Page_Home(props){

    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());   

    function InitialData(){
        const newOrigin = GetDataPage('site').filter(item => item.origin_ == 'home');
        const newData   = newOrigin.filter(item => item.idioma == idioma);
        return newData
    }

    function CurrentPage(type, data, index){
        switch (type) {
            case "Home":
                return <ModelPage_Home origin="home" data={ data } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } index={ index } />;

            case "Texto":
                return <ModelPage_TextSimple origin="home" data={ data } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } index={ index } />;

            case "Texto com título em destaque":
                return <ModelPage_TextTitle origin="home" data={ data } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } index={ index } />;

            case "Texto com imagem":
                return <ModelPage_TextPhoto origin="home" data={ data } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } index={ index } />;
            
            case "Seta":
                return <ModelPage_Arrow origin="home" data={ data } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } index={ index } />;

            case "Card com icones":
                return <ModelPage_CardIcons origin="home" data={ data } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } index={ index } />;

            case "Card com imagem":
                return <ModelPage_CardPhoto origin="home" data={ data } CkickPage={ props.CkickPage } setLoading={ props.setLoading } key={ index } index={ index } />;
        }
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
    }, []);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
        setDataPage(InitialData());
    }, [idioma]);

    return(
        <>
            {            
                dataPage.map((elem, index)=>{
                    return CurrentPage(elem.type, elem, index);
                })     
            }

            <ModelPage_Questions CkickPage={ props.CkickPage } setLoading={ props.setLoading } />
        </>   
    )
}