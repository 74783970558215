import { useState, useEffect } from "react";

import './MakeAppointment.css';

import { SetModalData, SetModalState } from "interface/PopUp";

export default function MakeAppointment(props){

    function OpenHealth(){
        SetModalData("Health", { "setLoading": props.setLoading })
        SetModalState("Health", true);
    }
    
    return(
        <div className={ props.textAlign == "left" ? "MakeAppointment align_left" : props.textAlign == 'right' ? "MakeAppointment align_right" : "MakeAppointment" }>
            <div className={ "btn " + props.bgColor } onClick={ ()=>{ OpenHealth() } }>{ props.nameBtn == "" ? "Marcar consulta" : props.nameBtn }</div>
        </div>
    )
}