import Axios from 'axios';
import { SetListData, SetListDataSingle } from 'interface/Data';

import { SetUserData } from 'interface/Users';

// Request Health
export function Reg_Health(contents, name, email, contact, date, hour, text, CallbackHour, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("current_page", "request_health");

    data.append('name', name);
    data.append('email', email);
    data.append('contact', contact);
    data.append('date', date);
    data.append('hour', hour);
    data.append('text', text);
    
    contents.forEach(element => {
        data.append('question[]', element.text);
        data.append('response[]', element.question);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/request_health.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {      
        switch (response.data) {
            case "Hora já utilizada":
                    CallbackHour();
                break;
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

// Send book
export function Reg_SendBook(email, name, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("current_page", "send_email");

    data.append('email', email);
    data.append('name', name);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/send_email.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {     
        switch (response.data) {
            case "Email enviado com sucesso":
                    CallbackSuccess();
                break;
            default:
                    CallbackError();
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}

// CheckedPayment
export function CheckedPayment(origin, idPayment, dateSaved, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("type", "generator_payment");

    data.append('origin', origin);
    data.append('idPayment', idPayment);
    data.append('dateSaved', dateSaved);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/payment_checked.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {   
        console.log(response.data);
        console.log(response.data['products']);
        
        switch (response.data['type']) {
            case "FINALIZED": case "finalized":
                    CallbackSuccess();
                break; 
            case "certo":
                    SetListDataSingle('products', response.data['products']);
                    SetUserData('user', response.data['user']);
                    CallbackSuccess();
                break; 

            default:
                    setTimeout(() => {
                        CheckedPayment(origin, idPayment, dateSaved, CallbackSuccess, CallbackError);
                    }, 3000);
                break;
        }

    }).catch((error)=>{
        setTimeout(() => {
            CheckedPayment(origin, idPayment, dateSaved, CallbackSuccess, CallbackError);
        }, 3000);
        // CallbackError();
    })
}