import { useState, useEffect } from "react";

import './CardPhoto.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import MakeAppointment from "components/MakeAppointment";

export default function  ModelPage_CardPhoto(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    const [ height, setHeight ] = useState(0);

    function InitialData(){
        const newOrigin = GetDataPage('site').filter(item => item.origin_ == props.origin);
        const newData   = newOrigin.filter(item => item.idioma == idioma);

        return newData
    }

    function OpenHealth(){
        SetModalData("Health", { "setLoading": props.setLoading })
        SetModalState("Health", true);
    }

    function TypeColor(value){
        switch (value) {
            case "#F5F5F5": case "#E7E3CE":
                return "color_1";

            case "#6B7D5C":
                return "color_2";
        
            default:
                return "color_1";
        }
    }

    useEffect(()=>{
        if(props.index != 0){
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }

        window.addEventListener('resize', function () {
            setHeight(1)
        });
    }, []);

    useEffect(()=>{
    }, [height]);

    return(
        <div className={ "ModelPage_CardPhoto " + spaceTop + " " + spaceBottom }>
            <div className="container">
                <table className="card_table" width="100%" cellPadding={ 0 } cellSpacing={ 0 }>
                    <tbody>
                        <tr>
                            <td className="card_image show_table" style={ { backgroundImage: 'url("' + props.data.contents[0].file + '")', width: "48%" } }>
                                <div className="bg_color" />

                                <table className="div_data table_align_left" width="100%" height="100%" cellPadding={ 0 } cellSpacing={ 0 }>
                                    <tbody className="show_data">
                                        <tr>
                                            <td className="title left">{ props.data.contents[0].title }</td>
                                        </tr>
                                        <tr>
                                            <td className="text left"><div dangerouslySetInnerHTML={ { __html: props.data.contents[0].text.replaceAll('&#34;', '"') } } /></td>
                                        </tr>
                                        <tr>
                                            <td className="btn"><div onClick={ ()=>{ OpenHealth() } }>{ props.data.contents[0].btn }</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>

                            <td width="40px" />

                            <td className="card_image show_table" style={ { backgroundImage: 'url("' + props.data.contents[1].file + '")', width: "48%" } }>
                                <div className="bg_color" />

                                <table className="div_data table_align_right" width="100%" height="100%" cellPadding={ 0 } cellSpacing={ 0 }>
                                    <tbody className="show_data">
                                        <tr>
                                            <td className="title right">{ props.data.contents[1].title }</td>
                                        </tr>
                                        <tr>
                                            <td className="text right"><div dangerouslySetInnerHTML={ { __html: props.data.contents[1].text.replaceAll('&#34;', '"') } } /></td>
                                        </tr>
                                        <tr>
                                            <td className="btn"><div onClick={ ()=>{ OpenHealth() } }>{ props.data.contents[1].btn }</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                {
                    props.data.contents.map((elem, index)=>{
                        return(
                            <div className="card_image card_div" id={ "card_height_" + index } key={ index } style={ { backgroundImage: 'url("' + elem.file + '")' } }>
                                <div className="bg_color" />
                                <div className={ index == 0 ? "div_data data_left" : "div_data data_right" }>
                                    <div className="show_data" id={ "card_" + index }>
                                        <div className={ index == 0 ? "title left" : "title right" }>{ elem.title }</div>
                                        <div className={ index == 0 ? "text left" : "text right" } dangerouslySetInnerHTML={ { __html: elem.text.replaceAll('&#34;', '"') } } />
                                        <div className="btn" onClick={ ()=>{ OpenHealth() } }>{ elem.btn }</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                } 
               
            </div>
            <div className="container">
                {
                    props.data.show_btn == "Não" ? null : <MakeAppointment setLoading={ props.setLoading } nameBtn={ props.data.name_btn } bgColor={ TypeColor(props.data.color) } textAlign={ ()=>{ } } />
                }
            </div>
        </div>
    )
}