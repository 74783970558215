import { useState, useEffect } from "react";

import './Login.css';

import { Access } from "services/login";

import { SetListPag } from "interface/Page";
import { SetModalState } from "interface/PopUp";

import { Link } from "react-router-dom";

export default function Page_Login(props){

    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function Register(event){
        event.preventDefault();
        props.setLoading(true);
        Access('logar', email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        props.CallbackSuccess(true);
    }

    function CallbackError(){
        props.setLoading(false);
        setMsgError('E-mail ou senha incorreta!');
    }

    function RecoverPassword(){
        SetModalState('RecoverPassword', true);
    }

    return(        
        <form onSubmit={ Register } className="Page_Login">
            <div className="container">
                <div className="title">Logar no site</div>
                <div className="div_login">
                    <div className="div_input">
                        <div className="name_input">Login (e-mail)</div>
                        <div className="input">
                            <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } required />
                        </div>
                    </div>
                    <div className="div_input">
                        <div className="name_input">Senha</div>
                        <div className="input">
                            <input type="password" className="" onChange={ (e)=>{ setPassword(e.target.value) } } required />
                        </div>
                    </div>
                    {
                        msgError == "" ? null : <div className="msg_error">{ msgError }</div>
                    }
                    <div className="div_button">
                        <button className="button_login">Login</button>
                    </div> 

                    <div className="div_recovery_access">
                        {
                            props.typePage == "page_login" ? 
                            <Link className="new_register" to="/register" onClick={ ()=>{ SetListPag('page', 'register'); props.ReturnIndex(); } }>
                                Registar
                            </Link> :
                            <div className="new_register" onClick={ ()=>{ props.typePage('register') } }>
                                Registar
                            </div>
                        }

                        <div className="new_register recovery" onClick={ ()=>{ SetModalState('RecoverPassword', true) } }>Recuperar senha</div>
                    </div>

                </div>
            </div>
        </form>
    )
}