import { useState, useEffect } from "react";

import './Footer.css';

import { GetDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Footer(props){

    const [ idioma, setIdioma ]         = useState(GetListPag('idioma'));
    const [ dataFooter, setDataFooter ] = useState(GetDataPage('config'));
    const [ textIdioma, setTextIdioma ] = useState([
        {
            "pt_pt": "Termos e condições",
            "pt_br": "Termos e condições",
            "en": "Terms and conditions",
            "es": "Términos y Condiciones"
        },
        {
            "pt_pt": "Política de privacidade",
            "pt_br": "Política de privacidade",
            "en": "Privacy Policy",
            "es": "Política de privacidad"
        },
        {
            "pt_pt": "Livro de reclamações",
            "pt_br": "Livro de reclamações",
            "en": "Libro de reclamaciones",
            "es": "Complaint book"
        },
        {
            "pt_pt": "Contato",
            "pt_br": "Contato",
            "en": "Contact",
            "es": "Contacto"
        },
        {
            "pt_pt": "E-mail",
            "pt_br": "E-mail",
            "en": "Email",
            "es": "Correo electrónico"
        },
        {
            "pt_pt": "Endereço",
            "pt_br": "Endereço",
            "en": "Address",
            "es": "Dirección"
        }
    ]);

    function OpenData(title, type){
        SetModalData('TextFooter', { "title": title, "type": type });
        SetModalState('TextFooter', true)
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
    }, []);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
    }, [idioma]);
    
    return(
        <footer id="contact">
            <div className="div_logo">
                <img alt="logotipo" src="./assets/footer_01.png" className="logo" />
            </div>
            <div className="div_text">
                <div onClick={ ()=>{ OpenData('Termos e condições', 'terms_conditions') } }>
                    { textIdioma[0][idioma] }
                </div>
                <div onClick={ ()=>{ OpenData('Politica de privacidade', 'privacy_policy') } }>
                    { textIdioma[1][idioma] }
                </div>                
                <div>
                    <a href="https://www.livroreclamacoes.pt/Inicio/" target="_blank">
                        { textIdioma[2][idioma] }
                    </a>
                </div> 

                <div className="data_contact">
                    { textIdioma[3][idioma] }: { dataFooter.contact_number }
                </div>
                <div className="">
                    { textIdioma[4][idioma] }: { dataFooter.contact_email }
                </div>
                <div className="">
                    { textIdioma[5][idioma] }: { dataFooter.contact_address }
                </div>               
            </div>
            <div className="div_icons">
                <div className="div_ers">
                    <img alt="logotipo" src="./assets/footer_02.png" className="ers" />
                </div>
                <div className="div_payment">
                    <img alt="logotipo" src="./assets/payment_4.png" className="img" />
                </div>
            </div>
        </footer>
    )
}