const ModalState = {
    Health : false,
    ReturnResponse : false,
    RecoverPassword : false,
    TextFooter : false,
    SendEmail : false,
    ShowFile : false,
    ReturnLogin : false,
    Payment : false,
    Purchase : false,
    ShowPurchase : false
};

const ModalData = {
    Health : {},
    ReturnResponse : {},
    RecoverPassword : {},
    TextFooter : {},
    SendEmail : {},
    ShowFile : {},
    ReturnLogin : {},
    Payment : {},
    Purchase : {},
    ShowPurchase : {}
}

const NotifyModalObserver = {
    Health : [],
    ReturnResponse : [],
    RecoverPassword : [],
    TextFooter : [],
    SendEmail : [],
    ShowFile : [],
    ReturnLogin : [],
    Payment : [],
    Purchase : [],
    ShowPurchase : []
}

const NotifyModalData = {
    Health : [],
    ReturnResponse : [],
    RecoverPassword : [],
    TextFooter : [],
    SendEmail : [],
    ShowFile : [],
    ReturnLogin : [],
    Payment : [],
    Purchase : [],
    ShowPurchase : []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
