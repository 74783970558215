import { useState, useEffect } from "react";

import './TextPhoto.css';

import { GetListPag } from "interface/Page";
import { GetDataPage } from "interface/Data";

import MakeAppointment from "components/MakeAppointment";

export default function ModelPage_TextPhoto(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newOrigin = GetDataPage('site').filter(item => item.origin_ == props.origin);
        const newData   = newOrigin.filter(item => item.idioma == idioma);
        return newData
    }

    function ShowImg(){
        return (
            <div className="div_img">
                <img alt="img" src={ props.data.file } className="img" />
            </div>
        )
    }

    function ShowText(value){
        return(
            <div className={ "div_text " + value }>
                {
                    props.data.title == "" ? null : <div className="title" style={ { textAlign: value } }>{ props.data.title }</div>
                }                
                <div className="text" style={ { textAlign: value } } dangerouslySetInnerHTML={ { __html: props.data.text.replaceAll('&#34;', '"') } } />
            </div>
        )
    }

    function TypeColor(value){
        switch (value) {
            case "#F5F5F5": case "#E7E3CE":
                return "color_1";

            case "#6B7D5C":
                return "color_2";
        
            default:
                return "color_1";
        }
    }

    useEffect(()=>{
        if(props.index != 0){            
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }
    }, []);

    return(
        props.data.file_align == 'bg_left' || props.data.file_align == 'bg_right' ?
        <div className={ "ModelPage_TextPhoto img_fixed " + spaceTop + " " + spaceBottom } style={ { backgroundImage: 'url("' + props.data.file + '")' } }>
            {
                props.data.color == "not" ? null : <div className="color_bg_img" style={ { backgroundColor: props.data.color } } />
            }
            <div className={ "container text_" + props.data.file_align + " " + TypeColor(props.data.color) }>
                <div className="show_img_bg">
                    { ShowText(props.data.file_align.replaceAll('bg_', '')) }
                    {
                        props.data.show_btn == "Não" ? null : <MakeAppointment setLoading={ props.setLoading } nameBtn={ props.data.name_btn } bgColor={ TypeColor(props.data.color) } textAlign={ props.data.file_align.replaceAll('bg_', '') } />
                    }
                </div>
            </div>
        </div>
        :
        <div className={ "ModelPage_TextPhoto " + spaceTop + " " + spaceBottom } style={ { background: props.data.color == "" ? "#F5F5F5" : props.data.color } }>
            <div className={ "container show_img_side " + TypeColor(props.data.color) }>
                {
                    props.data.file_align == "right" ? 
                    <>
                        { ShowText('left') }
                        { ShowImg() }
                    </> :
                    <>
                        { ShowImg() }
                        { ShowText('right') }
                    </>
                }
            </div>

            <div className="container">
                {
                    props.data.show_btn == "Não" ? null : <MakeAppointment setLoading={ props.setLoading } nameBtn={ props.data.name_btn } bgColor={ TypeColor(props.data.color) } textAlign={ ()=>{ } } />
                }
            </div>
        </div>
    )
}