import { cookiesRegister, cookie_site, cookie_site_id } from 'fixedData';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

let ListPag = {
    "idioma"   : 'pt_pt',
    "page"     : 'index',
    "page_id"  : 0
};

let NotifyListPag = {
    "idioma"   : [],
    "page"     : [],
    "page_id"  : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    // Obtém a data atual
    const day = new Date();

    // Adiciona 3 dias
    const add3Days = new Date();
    add3Days.setDate(day.getDate() + 3);
    if(key == "page"){
        cookies.set(cookie_site, value, { path: '/', expires: add3Days }, cookiesRegister); 
    }

    // login    
    if(key == "page_id"){
        cookies.set(cookie_site_id, value, { path: '/', expires: add3Days }, cookiesRegister); 
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}
