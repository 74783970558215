import React, { useState, useEffect } from "react";

import './Payment.css';

import { SvgClose, SvgSetaRight } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { CheckedPayment } from "services";
import { SetPurchaseSingle } from "interface/Purchase";

import { useNavigate } from "react-router-dom";
import { SetListPag } from "interface/Page";

export default function PopUp_Payment(props){

    const navigate = useNavigate();

    const [ status, setStatus ] = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ urlPayment, setUrlPayment ] = useState('');

    const [ checkedStatusPayment, setCheckedStatusPayment ] = useState(null);

    function ClosePopUp(){
        setUrlPayment('');
        StatusPayment('close');
        SetModalState('Payment', false);
    }

    function ClosePopUpPayment(){ 
        setStatus(true);
        setUrlPayment('');      
        CheckedPayment('alt_status', modalData.id, modalData.dateSaved, ()=>{ AltPage() }, ()=>{ });
    }

    function AltPage(){
        setTimeout(() => {             
            navigate('/login');    
            props.ReturnIndex();
            SetListPag('page', 'login');   

            SetModalState('Payment', false); 
            SetPurchaseSingle('remuve_all'); 

            setStatus(false);
        }, 600);
    }

    function StatusPayment(type){
        if(type === "close"){
            clearInterval(checkedStatusPayment);
            setCheckedStatusPayment(null);
        }else {
            CheckedPayment('checked_id', modalData.id, modalData.dateSaved, ()=>{ ClosePopUpPayment() }, ()=>{ });
        }
    }

    useEffect(()=>{
        RegisterModalData('Payment', setModaldata);
        RegisterModalObserver('Payment', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setUrlPayment(modalData.url);
            StatusPayment();
            const iframe = document.getElementById('full_screen');
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

            // Alterar o overflow do elemento com ID 'content' dentro do iframe
            const timeLink = setInterval(() => {
                if(iframeDocument.querySelector('body')){
                    const contentElement = iframeDocument.querySelector('body');
                    contentElement.style.overflow = 'hidden';

                    clearInterval(timeLink);
                }
            }, 2000);
        }
    }, [showPopUp]);
    
    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all Payment">
                        {
                            status ? 
                                <div className="div_msg_status">
                                    <div className="ld-ripple">
                                        <div />
                                        <div />
                                    </div>
                                    Aguarde, será redirecionado para a página de login.
                                </div>
                            : null
                        }
                        <div className="div_data">
                            <div className="title title_file">
                                <div className="popup_close" onClick={ ()=>{ ClosePopUp(); } }>
                                    <SvgClose color="#F00000" className="icons" />
                                </div>
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <iframe src={ urlPayment } className="full_screen" id="full_screen" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}