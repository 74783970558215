import { useState, useEffect } from "react";

import './Home.css';

import Iframe from 'react-iframe';

import { Reg_SendBook } from "services";
import { SetModalData, SetModalState } from "interface/PopUp";
import PopUP_SendEmail from "components/PopUp/SendEmail";

export default function ModelPage_Home(props){

    const [ email, setEmail ]       = useState('');
    const [ msgError, setMsgError ] = useState('');
    
    function ShowVideo(type, video){
        switch (type) {
            case 'vimeo':
                let linkVimeo = video.split('/');
                return <Iframe className="div_video" src={ "https://player.vimeo.com/video/" + linkVimeo[3] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;

            case 'youtube':
                let linkYoutube = video.split('=');
                return <Iframe className="div_video" src={ "https://www.youtube.com/embed/" + linkYoutube[1] } frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen="" />;
        }
    }

    function SendEmail(){
        if(email.includes('@') && email.includes('.')){
            setMsgError('');
            SetModalData('SendEmail', { "email": email, "setLoading": props.setLoading });
            SetModalState('SendEmail', true);
        }else {
            setMsgError('E-mail inválido!');
        }
    }

    return(
        <>
            <div className="ModelPage_Home" style={ { backgroundImage: 'url("' + props.data.file + '")' } }>
                <div className="color_bg_img"/>
                <div className="container">
                    <div className="div_title">{ props.data.title }</div>
                    <div className="div_subtitle">{ props.data.subtitle }</div>
                    <div className="div_text" dangerouslySetInnerHTML={ { __html: props.data.text.replaceAll('&#34;', '"') } } />
                    
                    <div className="div_input">
                        <input type="text" className="input_email" placeholder="Coloca aqui o teu e-mail. Temos umas surpresas para ti!" onChange={ (e)=>{ setEmail(e.target.value) } } />
                        <div className="btn_save" onClick={ ()=>{ SendEmail() } }>
                            Enviar
                        </div>
                    </div> 
                   
                </div>
                {
                    msgError == '' ? null : 
                    <div className="container">
                        <div className="div_show_error">
                            { msgError }
                        </div>
                    </div>
                }
                <div className="container show_div_video">
                    <div className="div_video">
                        { ShowVideo(props.data.type_video, props.data.video) }
                    </div>
                </div>
                <div className="div_space_">
                    <div className="left" />
                    <div className="right" />
                </div>
            </div>
            <PopUP_SendEmail />
        </>
    )
}