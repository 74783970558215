import React, { useState, useEffect } from "react";

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import './Health.css';

import TextareaAutosize from 'react-textarea-autosize';

import { Reg_Health } from "services";

import { GetListPag, RegisterListPag } from "interface/Page";
import { GetDataPage, SetListDataSingle } from "interface/Data";

import { listHour } from "fixedData";

import Axios from 'axios';

export default function PopUP_Health(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    
    const [ minDate, setMinDate ] = useState(CheckedDate('min'));
    const [ maxDate, setMaxDate ] = useState(CheckedDate('max'));

    const [ dataPage, setDataPage ] = useState("");

    const [ idioma, setIdioma ]                 = useState(GetListPag('idioma'));
    const [ hourDisponible, setHourDisponible ] = useState(GetDataPage('unavailable_time'));
    
    const [ status, setStatus ]             = useState(0);
    const [ stage, setStage ]               = useState(0);
    const [ textQuestion, setTextQuestion ] = useState([]);

    const [ name, setName ]         = useState('');
    const [ email, setEmail ]       = useState('');
    const [ contact, setContact ]   = useState('');
    const [ date, setDate ]         = useState('');
    const [ hour, setHour ]         = useState('');
    const [ text, setText ]         = useState('');
    const [ msgError, setMsgError ] = useState('');

    function InitialData(){
        const newData = GetDataPage('questionnaire').find(item => item.idioma == idioma);
        return newData;
    }

    function CheckedDate(type){
        const currentDate = new Date();
        let month = currentDate.getMonth() + 1;
        if(month > 9){
            month = month;
        }else {
            month = "0" + month;
        }

        let day = currentDate.getDate();
        if(day > 9){
            day = day;
        }else {
            day = "0" + day;
        }
        let dataMin = currentDate.getFullYear() + "-" + month + "-" + day;

        if(type == 'min'){
            return dataMin;

        }else if(type == 'max'){
            let date    = new Date(dataMin);
            let newDate = new Date();
            newDate.setDate(date.getDate() + 30);
            
            let month = newDate.getMonth() + 1;
            if(month > 9){
                month = month;
            }else {
                month = "0" + month;
            }

            let day = newDate.getDate();
            if(day > 9){
                day = newDate.getDate();
            }else {
                day = "0" + newDate.getDate();
            }
            return newDate.getFullYear() + "-" + month + "-" + day;
        }
    }

    function HandleData(index, value){
        const newData  = [...textQuestion];
        newData[index]['question'] = value;
        setTextQuestion(newData);
    }

    function CheckedDay(value){   
        let selectDate = new Date(value);
        setDate(value);
        if(selectDate.getDay() == 6){
            setMsgError('Data indisponível!');
        }else {
            setMsgError('');
        }
    }

    function ShowListHour(){     
        const newData = [];   
        let selectDate = new Date(date);
        
        const data = new FormData();
        data.append('current_page', 'show_data_hour');
        Axios({
            url     : process.env.REACT_APP_API_URL + 'php/list_data.php',
            mode    : 'no-cors',
            method  : 'POST',
            data    : data
        })
        .then(response => {
            SetListDataSingle('unavailable_time', response.data.unavailable_time);
        });

        if(GetDataPage('unavailable_time').find(item => item.date == date)){
            const dateSelected = GetDataPage('unavailable_time').filter(item => item.date == date);
            listHour.map((elem, index)=>{
                if(selectDate.getDay() == 5){
                    if(index <= 7){
                        if(dateSelected.find(item => item.hour == elem)){
                            newData.push({ "value": elem + " - indisponível", "status": true });
                        }else {
                            newData.push({ "value": elem, "status": false });
                        }
                    }else {
                        newData.push({ "value": elem, "status": false });
                    }
                }else {
                    if(dateSelected.find(item => item.hour == elem)){
                        newData.push({ "value": elem + " - indisponível", "status": true });
                    }else {
                        newData.push({ "value": elem, "status": false });
                    }
                }
            });

        }else {            
            listHour.map((elem, index)=>{
                if(selectDate.getDay() == 5){
                    if(index <= 7){
                        newData.push({ "value": elem, "status": false });
                    }
                }else {
                    newData.push({ "value": elem, "status": false });
                }
            });
        }

        return(            
            <select className="list_hour" onChange={ (e)=>{ setHour(e.target.value) } } value={ hour } required>
                <option value="" disabled={ true }>#</option>
                {                    
                    newData.map((elem, index)=>{
                        return(
                            <option value={ elem.value } key={ index } disabled={ elem.status }>{ elem.value }</option>
                        )
                    })
                }
            </select>
        )
    }

    function CurrentStage(){
        switch (stage) {
            case 0:
                return(
                    <div className="show_data">
                        <div className="popup_text" dangerouslySetInnerHTML={ { __html: dataPage.text ? dataPage.text.replaceAll('&#34;', '"') : null } } />
                        <div className="btn" onClick={ ()=>{ setStage(1); setTextQuestion(dataPage.contents) } }>Iniciar</div>
                    </div>
                );

            case 1:
                return(
                    <div className="show_data">
                        {
                            textQuestion.map((elem, index)=>{
                                return(
                                    <div className="" key={ index }>
                                        <div className="list_data_input">
                                            <div className="popup_text" dangerouslySetInnerHTML={ { __html: elem.text ? elem.text.replaceAll('&#34;', '"') : null } } />
                                        </div>
                                        <div className="list_data_input">
                                            <div className="div_input space_div">
                                                <div className="name_input">Resposta</div>
                                                <TextareaAutosize className="input textarea" onChange={ (e)=>{ HandleData(index, e.target.value) } } value={ elem.response } required placeholder="Descreva aqui..." />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="btn" onClick={ ()=>{ setStage(2); } }>
                            Solicitar consulta
                        </div>
                    </div>
                )

            case 2:
                return(
                    <div className="show_data">
                        <div className="list_data_input">
                            <div className="div_input space_div">
                                <div className="name_input">Nome*</div>
                                <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } required />
                            </div>
                            <div className="div_input space_div">
                                <div className="name_input">E-mail*</div>
                                <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required />
                            </div>
                            <div className="div_input">
                                <div className="name_input">Contato*</div>
                                <input type="text" className="contact" onChange={ (e)=>{ setContact(e.target.value) } } value={ contact } required />
                            </div>
                        </div>

                        <div className="list_data_input">
                            <div className="div_input">
                                <div className="name_input">Escolha uma data para consulta*</div>
                                <input type="date" id="date_" className="input_date" onChange={ (e)=>{ CheckedDay(e.target.value); } } min={ minDate } max={ maxDate } value={ date } required />
                            </div>
                            {
                                msgError == "" ?
                                    date == '' ? null : 
                                    <div className="div_input">
                                        <div className="name_input">Horários*</div>
                                        { ShowListHour() }
                                    </div> 
                                : <div className="error_date">{ msgError }</div>
                            }
                        </div>

                        <div className="list_data_input">
                            <div className="div_input space_div">
                                <div className="name_input">Obervação</div>
                                <TextareaAutosize className="input textarea" onChange={ (e)=>{ setText(e.target.value) } } value={ text.replace(/<br>/g,'\n') } placeholder="Descreva aqui..." />
                            </div>
                        </div>
                        
                        <div className="list_data_input">
                            <button type="submit" className="btn btn_save" onClick={ ()=>{  } }>Marcar consulta</button>
                        </div>
                    </div>
                )
        }
    }

    function saveData(event){
        event.preventDefault();
        if(msgError == ''){
            modalData.setLoading(true);
            Reg_Health(dataPage.contents, name, email, contact, date, hour, text, CallbackHour, CallbackSuccess, CallbackError);
        }else {
            setMsgError('É necessário definir a hora da consulta')
        }
    }

    function CallbackHour(){
        modalData.setLoading(false);
        ShowListHour();
        SetModalData('ReturnResponse', { "page": "erro", "text": "A hora selecionada acabou de ser utilizado por outra pessoa, por favor selecione um novo horário" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackSuccess(){
        modalData.setLoading(false);
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        modalData.setLoading(false);
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setDataPage("");
        
        setStage(0);
        setTextQuestion([]);

        setName('');
        setEmail('');
        setContact('');
        setDate('');
        setHour('');
        setText('');
        setMsgError('');
        SetModalState('Health', false);
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterModalData('Health', setModaldata);
        RegisterModalObserver('Health', setShowPopUp);
    }, []);
    
    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(InitialData());
            setIdioma(GetListPag('idioma'));
            setHourDisponible(GetDataPage('unavailable_time'));
            document.body.style.overflow = "hidden";

        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    useEffect(()=>{
        if(showPopUp == true){
            ShowListHour();
            setHour('');
        }
    }, [date])

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all health">
                        <div className="div_data type_div">
                            <div className="popup_title">{ dataPage.title }</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <form onSubmit={ saveData } className="content">
                                {
                                    CurrentStage()
                                }
                            </form>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
