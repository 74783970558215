import { useState, useEffect } from "react";

import './Product.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetPurchase, RegisterPurchase, SetPurchaseSingle } from "interface/Purchase";

import { SvgArrow_2, SvgSearch } from "components/SvgFile";

import { Link, useNavigate } from "react-router-dom";

export default function Page_Product(props){  

    const navigate = useNavigate();

    const [ idioma, setIdioma ] = useState(GetListPag('idioma'));
    const [ products, setProducts ] = useState(ShowProduct());
    const [ purchase, setPurchase ] = useState(GetPurchase('purchase'));
    const [ category, setCategory ] = useState(GetDataPage('products_category'));

    const [ slideShow, setSlideShow ] = useState(InitialData('slide_show'));
    const [ title, setTitle ] = useState(ShowText('title'));
    const [ text, setText ] = useState(ShowText('text'));
    const [ listProduct, setListProduct ] = useState(InitialData('products'));
    const [ listTag, setListTag ] = useState(InitialData('tag'));
    
    let countSlide = 0;
    const [ qtdCount, setQtdCount ] = useState(6);
    const [ limitProduct, setLimitProduct ] = useState(3);

    const [ textIdioma, setTextIdioma ] = useState([
        {
            "pt_pt": "Pesquisar...",
            "pt_br": "Pesquisar...",
            "en": "Search...",
            "es": "Buscar..."
        },
        {
            "value": "all",
            "pt_pt": "Lista dos produtos",
            "pt_br": "Lista dos produtos",
            "en": "List of products",
            "es": "Lista de productos"
        }
    ]);

    const [ statusSearch, setStatusSearch ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ selectSearch, setSelectSearch ] = useState('all');

    const SlideOpt = {
        infinite: true,
        indicators: true,
        arrows: true
    };

    function ShowProduct(){
        const newData = GetDataPage('products').filter(item => item.idioma == idioma);
        return newData;
    }

    function InitialData(type){
        if(type == 'slide_show'){
            const origin  = GetDataPage('products').filter(item => item.idioma == idioma);
            const optSlide = origin.filter(item => item.slide_show == 'Sim');
            return optSlide;

        }else if(type == 'products'){
            const newData = GetDataPage('products').filter(item => item.idioma == idioma);
            return newData;
        }else if(type == 'tag'){
            const newData = [];
            GetDataPage('products_category').map((elem, index)=>{
                if(elem.type == 2){
                    newData.push(elem);
                }
            })
            return newData;
        }
    }
    
    function SearchProduct(type){
        const newData   = GetDataPage('products').filter(item => item.idioma == idioma);
        const newList   = [];
        if(type == 'clear'){
            setSearch('');
            setSelectSearch('all');
            setListProduct(newData);
            setStatusSearch(false);
        }else {
            if(search != ""){
                newData.forEach(item =>{
                    if(item.name.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.supplier_name.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.text.toLowerCase().indexOf(search.toLowerCase()) != -1){
                        newList.push(item);
                    }
                });
                let duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setListProduct(duplicate);
            }else {
                setListProduct(InitialData('products'));
            }
        }
    }

    function SearchCategory(value){
        setSelectSearch(value);
        const newData = GetDataPage('products').filter(item => item.idioma == idioma);
        if(value != 'all'){
            const newList = [];
            newData.map((elem, index)=>{
                elem.tag.map((elem_1, index_1)=>{
                    if(elem_1.name_tag == value){
                        newList.push(elem);
                    }
                })
            });
            setListProduct(newList);
        }else {
            setListProduct(InitialData('products'));
        }
    }

    function OpenProductSlide(value){
        navigate('/' + value);
        SetListPag('page', 'product_details'); 
        props.ReturnIndex();
    }
    
    function ShowText(type){
        const newData = GetDataPage('products_text');
        switch (idioma) {
            case "pt_pt":
                if(type == 'text'){
                    return newData[0].text_pt_pt;
                }else{
                    return newData[0].title_pt_pt;
                }
            case "pt_br":
                if(type == 'text'){
                    return newData[0].text_pt_br;
                }else{
                    return newData[0].title_pt_br;
                }
            case "en":
                if(type == 'text'){
                    return newData[0].text_en;
                }else{
                    return newData[0].title_en;
                }
            case "es":
                if(type == 'text'){
                    return newData[0].text_es;
                }else{
                    return newData[0].title_es;
                }
        }
    }
    
    function AltPosition_prev(){
        var stream = document.querySelector('.div_list_img');
        var items = document.querySelectorAll('.show_img');
        
        stream.insertBefore(items[items.length - 1], items[0]);
        items = document.querySelectorAll('.show_img');
        
        AltEffect();
        countSlide = 0;
    }
    
    function AltPosition_next(){
        var stream = document.querySelector('.div_list_img');
        var items = document.querySelectorAll('.show_img');
        
        stream.appendChild(items[0]);
        items = document.querySelectorAll('.show_img');
        
        AltEffect();
        countSlide = 0;
    }

    function StartSlideShow(){
        if(GetListPag('currentPage') == 'product'){
            if(document.querySelector('.div_list_img')){
                var stream = document.querySelector('.div_list_img');
                var items = document.querySelectorAll('.show_img');
                
                stream.appendChild(items[0]);
                items = document.querySelectorAll('.show_img');
                
                AltEffect();
            }
        }
    }

    function AltEffect(){
        document.querySelectorAll('.title_slide').forEach((elem, index) => {
            elem.classList.add('title_close');
            elem.classList.remove('title');
            setTimeout(() => {
                elem.classList.remove('title_close');
            }, 1000);
            setTimeout(() => {
                elem.classList.add('title');
            }, 600);
        });
    }

    useEffect(()=>{
        RegisterListPag('idioma', setIdioma);
        RegisterPurchase('purchase', setPurchase);
        RegisterDataPage('products', setProducts);
        
        if(slideShow.length > 1){
            const altSlideShow = setInterval(() => {
                countSlide++;
                if(countSlide === 6){
                    countSlide = 0;
                    StartSlideShow(); 
                }
            }, 1000); 
        }
    }, []);

    useEffect(()=>{
        setPurchase(GetPurchase('purchase'));
    }, [purchase]);

    useEffect(()=>{
        setIdioma(GetListPag('idioma'));
        setSlideShow(InitialData('slide_show'));
        setCategory(GetDataPage('products_category'));

        setSlideShow(InitialData('slide_show'));
        setTitle(ShowText('title'));
        setText(ShowText('text'));
        setListProduct(InitialData('products'));
        setListTag(InitialData('tag'))
    }, [idioma]);
    
    return(
        <div className="Page_Product">            
            <div className="div_show_title">
                <div className="container">
                    <div className="div_title">{ title }</div>
                </div>
            </div>
            
            <div className="" style={ { width: '100%' } }>
                <Slide {...SlideOpt}>
                    {
                        slideShow.map((elem, index)=>{
                            return(
                                <div className="each-slide-effect" key={ index } onClick={ ()=>{ OpenProductSlide(elem.url_page) } }>
                                    <div className="show_img_slide_show" style={ { backgroundImage: 'url("' + elem.slide_file + '")' } } />
                                </div>
                            )
                        })
                    }
                </Slide>
            </div>
            
            <div className="text_page">
                <div className="container">
                    <div className="div_text" dangerouslySetInnerHTML={ { __html: text.replaceAll('&#34;', '"') } } />
                </div>
            </div>

            <div className="list_product">
                <div className="container">
                    <div className="div_search">
                        {
                            statusSearch ? 
                            <div className="clear_search" onClick={ ()=>{ SearchProduct('clear') } }>Limpar pesquisa</div> : null
                        }
                        <input type="text" className="" onChange={ (e)=>{ setSearch(e.target.value) } } value={ search } placeholder={ textIdioma[0][idioma] } />
                        <div className="div_icon">
                            <select onChange={ (e)=>{ SearchCategory(e.target.value) } } value={ selectSearch }>
                                <option value="all">{ textIdioma[1][idioma] }</option>
                                {
                                    listTag.map((elem, index)=>{
                                        return(
                                            <option value={ elem.name } key={ index }>{ elem.name }</option>
                                        )
                                    })
                                }
                            </select>
                            <div className="" onClick={ ()=>{ SearchProduct('') } }>
                                <SvgSearch className="icons" color="#6B7D5C" />
                            </div>
                        </div>
                    </div>
                    <div className="div_list_product">
                        {
                            listProduct.map((elem, index)=>{
                                if(statusSearch){                                  
                                    return (
                                        <div className="show_product" key={ index }>
                                            <Link className="div_img" to={ "/" + elem.url_page } onClick={ ()=>{ SetListPag('page', 'product_details'); props.ReturnIndex(); } }>
                                                <img alt="img" src={ elem.file == "" ? "./assets/not_file.jpg" : elem.file } className={ elem.file == "" ? "img_product not_file" : "img_product" } loading="lazy" />
                                            </Link>

                                            <div className="div_name">
                                                <div className="cash">  
                                                    Valor
                                                    {
                                                        elem.cash_promotional == "" ? " € " + elem.cash :
                                                        <>
                                                            <span className="promotional"> € { elem.cash }</span> por € { elem.cash_promotional }
                                                        </>
                                                    }
                                                </div>
                                                <div className="name_product">
                                                    { elem.name }
                                                </div>
                                                <div className={ elem.qtd == 0 && elem.qtd_total == 0 ? "div_btn div_unavailable" : "div_btn" }>                                                        
                                                    {
                                                        elem.qtd == 0 && elem.qtd_total == 0 ? 
                                                        <div className="div_cart div_cart_active unavailable">
                                                            <div className="btn_product">Indisponível</div>
                                                        </div> : <div />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }else {                                    
                                    if(index <= limitProduct){
                                        return (
                                            <div className="show_product" key={ index }>
                                                <Link className="div_img" to={ "/" + elem.url_page } onClick={ ()=>{ SetListPag('page', 'product_details'); props.ReturnIndex(); } }>
                                                    <img alt="img" src={ elem.file == "" ? "./assets/not_file.jpg" : elem.file } className={ elem.file == "" ? "img_product not_file" : "img_product" } loading="lazy" />
                                                </Link>

                                                <div className="div_name">
                                                    <div className="cash">
                                                        Valor
                                                        {
                                                            elem.cash_promotional == "" ? " € " + elem.cash :
                                                            <>
                                                                <span className="promotional"> € { elem.cash }</span> por € { elem.cash_promotional }
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="name_product">
                                                        { elem.name }
                                                    </div>
                                                    <div className={ elem.qtd == 0 && elem.qtd_total == 0 ? "div_btn div_unavailable" : "div_btn" }>                                                        
                                                        {
                                                            elem.qtd == 0 && elem.qtd_total == 0 ? 
                                                            <div className="div_cart div_cart_active unavailable">
                                                                <div className="btn_product">Indisponível</div>
                                                            </div> : <div />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                }
                            })
                        }
                    </div>
                    {
                        statusSearch == false ? 
                            listProduct.length <= 3 ? null :
                            limitProduct >= listProduct.length ? null :
                            <div className="show_all_products">
                                <div className="div_cart" onClick={ ()=>{ setLimitProduct(limitProduct + 8) } }>
                                    Mostrar mais produtos
                                </div>
                            </div>
                        : null
                    }
                </div>
            </div>
            
            {
                category.map((elem, index)=>{
                    if(elem.type == 0){                    
                        if(elem.highlight == 1){
                            return(
                            <div className="list_product" key={ index }>
                                <div className="container">
                                    <div className="div_title title_highlight">{ elem.name }</div>
                                    <div className="div_list_product">
                                        {
                                            elem.list_product.map((elem_1, index_1)=>{
                                                let showDataProduct = products.find(item => item.id == elem_1);
                                                return(                                                    
                                                    <div className="show_product" key={ index_1 }>
                                                        <Link className="div_img" to={ "/" + showDataProduct.url_page } onClick={ ()=>{ SetListPag('page', 'product_details'); props.ReturnIndex(); } }>
                                                            <img alt="img" src={ showDataProduct.file == "" ? "./assets/not_file.jpg" : showDataProduct.file } className={ showDataProduct.file == "" ? "img_product not_file" : "img_product" } loading="lazy" />
                                                        </Link>

                                                        <div className="div_name">
                                                            <div className="cash">
                                                                Valor 
                                                                {
                                                                    showDataProduct.cash_promotional == "" ? " € " + showDataProduct.cash :
                                                                    <>
                                                                        <span className="promotional"> € { showDataProduct.cash }</span> por € { showDataProduct.cash_promotional }
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className="name_product">
                                                                { showDataProduct.name }
                                                            </div>
                                                            <div className={ showDataProduct.qtd == 0 && showDataProduct.qtd_total == 0 ? "div_btn div_unavailable" : "div_btn" }>
                                                                {
                                                                    showDataProduct.qtd == 0 && showDataProduct.qtd_total == 0 ? 
                                                                    <div className="div_cart div_cart_active unavailable">
                                                                        <div className="btn_product">Indisponível</div>
                                                                    </div> : <div />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }) 
                                        }
                                    </div>
                                </div>
                            </div>
                            )
                        }
                    }
                })
            }

        </div>
    )
}