import { useState, useEffect } from 'react';

import './TextSimple.css';

import MakeAppointment from "components/MakeAppointment";

import { GetListPag } from 'interface/Page';
import { GetDataPage } from 'interface/Data';

export default function ModelPage_TextSimple(props){

    const [ spaceTop, setSpaceTop ]       = useState('');
    const [ spaceBottom, setSpaceBottom ] = useState('');
    
    const [ idioma, setIdioma ]     = useState(GetListPag('idioma'));
    const [ dataPage, setDataPage ] = useState(InitialData());

    function InitialData(){
        const newOrigin = GetDataPage('site').filter(item => item.origin_ == props.origin);
        const newData   = newOrigin.filter(item => item.idioma == idioma);
        return newData
    }

    function TypeColor(value){
        switch (value) {
            case "#F5F5F5": case "#E7E3CE":
                return "color_1";

            case "#6B7D5C":
                return "color_2";
        
            default:
                return "color_1";
        }
    }

    useEffect(()=>{
        if(props.index != 0){            
            if(dataPage[props.index - 1]['type'] == 'Seta'){
                setSpaceTop('Space_Arrow_bottom');
            }
            if(dataPage[props.index + 1]){
                if(dataPage[props.index + 1]['type'] == 'Seta'){
                    setSpaceBottom('Space_Arrow_top');
                }
            }
        }else {
        }
    }, []);

    return(
        <div className={ "ModelPage_TextSimple " + spaceTop + " " + spaceBottom } style={ { background: props.data.color == "" ? "#F5F5F5" : props.data.color } }>
            <div className={ "container " + TypeColor(props.data.color) }>
                {
                    props.data.title == "" ? null : <div className="title" style={ { textAlign: props.data.file_align } }>{ props.data.title }</div>
                }                
                <div className={ props.data.title == "" ? "text" : "text space_top" } dangerouslySetInnerHTML={ { __html: props.data.text.replaceAll('&#34;', '"') } } />

                {
                    props.data.show_btn == "Não" ? null : <MakeAppointment setLoading={ props.setLoading } nameBtn={ props.data.name_btn } bgColor={ TypeColor(props.data.color) } textAlign={ ()=>{ } } />
                }
            </div>
        </div>
    )
}