import React, { useState, useEffect } from "react";

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import './SendEmail.css';

import { Reg_SendBook } from "services";

export default function PopUP_SendEmail(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ name, setName ]   = useState('');
    const [ email, setEmail ] = useState('');

    function saveData(event){
        event.preventDefault();
        modalData.setLoading(true);
        Reg_SendBook(email, name, CallbackSuccess, CallbackError);
    }

    function CallbackSuccess(){
        modalData.setLoading(false);
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "ok!" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        modalData.setLoading(false);
        ClosePopUp();
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setName('');
        setEmail('');
        SetModalState('SendEmail', false);
    }

    useEffect(()=>{
        RegisterModalData('SendEmail', setModaldata);
        RegisterModalObserver('SendEmail', setShowPopUp);
    }, []);
    
    useEffect(()=>{
        if(showPopUp == true){
            setEmail(modalData.email);
            document.body.style.overflow = "hidden";

        }else {
            document.body.style.overflow = null;
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all SendEmail">
                        <div className="div_data type_div">
                            <div className="popup_title"></div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <form onSubmit={ saveData } className="content">
                                <div className="list_data_input">
                                    <div className="div_input space_div">
                                        <div className="name_input">Coloca aqui o teu nome*</div>
                                        <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength={ 140 } required />
                                    </div>
                                    <div className="div_input space_div">
                                        <div className="name_input">E-mail*</div>
                                        <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } required />
                                    </div>                        
                                </div>
                                <div className="list_data_input">
                                    <button type="submit" className="btn btn_save">Salvar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
